import { add, format, isValid, startOfMonth, sub } from 'date-fns';
import { TemplateResult, unsafeCSS } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENButton } from '../button/button';
import { ENChipGroup } from '../chip-group/chip-group';
import { ENChip } from '../chip/chip';
import { ENInlineTimeSelector } from '../inline-time-selector/inline-time-selector';
import { ENRangeCalendar } from '../range-calendar/range-calendar';
import { ENSwitch } from '../switch/switch';
import { ENTimezoneDropdown } from '../timezone-dropdown/timezone-dropdown';
import styles from './double-range-calendar.scss';

/**
 * Component: en-double-range-calendar
 * @slot - The components content
 */
export class ENDoubleRangeCalendar extends ENElement {
  static el = 'en-double-range-calendar';

  private elementMap = register({
    elements: [
      [ENButton.el, ENButton],
      [ENRangeCalendar.el, ENRangeCalendar],
      [ENSwitch.el, ENSwitch],
      [ENChipGroup.el, ENChipGroup],
      [ENChip.el, ENChip],
      [ENInlineTimeSelector.el, ENInlineTimeSelector],
      [ENTimezoneDropdown.el, ENTimezoneDropdown]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private rangeCalendarEl = unsafeStatic(this.elementMap.get(ENRangeCalendar.el));
  private buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));
  private switchEl = unsafeStatic(this.elementMap.get(ENSwitch.el));
  private chipEl = unsafeStatic(this.elementMap.get(ENChip.el));
  private chipGroupEl = unsafeStatic(this.elementMap.get(ENChipGroup.el));
  private inlineTimeSelectorEl = unsafeStatic(this.elementMap.get(ENInlineTimeSelector.el));
  private timezoneDropdownEl = unsafeStatic(this.elementMap.get(ENTimezoneDropdown.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * If true will keep old tooltip, else replace with new tooltip
   * Default is true
   */
  @property({ type: Boolean })
  keepOldTooltip?: boolean = true;

  /**
   * Current Month/Year. Recommended not to set it. Set it only if in some case today's date will not be equal to new Date()
   */
  @property()
  currentDate = new Date();

  /**
   * Current Month/Year display in header. By default it is initialized to first day of current date month. Recommended not to set it explicitly.
   */
  @property()
  navDate: any;

  /**
   * If true show footer along with footer controls. Default is true.
   */
  @property({ type: Boolean })
  showFooter?: boolean = true;

  /**
   * If true hide Done button. Default is false.
   */
  @property({ type: Boolean })
  hideDone?: boolean = false;

  /** This property allows chaning the look and feel of filter chips (Last Week/Last Month/Last Quarter)
   * - **default**:  This `default` variant will show chips as Last Week/Last Month/Last Quarter along with neutral variant chip
   * - **absolute**: This `absolute` variant will show chips as 7 Days/30 Days/90 Days along with secondaryV2 variant chip
   * Default is default.
   */
  @property()
  filterChipVariant?: 'default' | 'absolute' = 'default';

  /**
   * If true show Last Week Chip. Default is false.
   */
  @property({ type: Boolean })
  showLastWeekChip?: boolean = false;

  /**
   * If true show Last Month Chip. Default is false.
   */
  @property({ type: Boolean })
  showLastMonthChip?: boolean = false;

  /**
   * If true show Last Quarter Chip. Default is false.
   */
  @property({ type: Boolean })
  showLastQuarterChip?: boolean = false;

  /**
   * If true, disable reset button. Default is false.
   */
  @property({ type: Boolean })
  forceDisableResetButton: boolean = false;

  /**
   * If true, disable done button. Default is false.
   */
  @property({ type: Boolean })
  disableDoneButton: boolean = false;

  /**
   * Minimum date for disabled dates (format: yyyy/mm/dd)
   */
  @property()
  disabledMinDate?: string;

  /**
   * Maximum date for disabled dates (format: yyyy/mm/dd)
   */
  @property()
  disabledMaxDate?: string;

  /**
   * If set to true, then show timezone field and switch if enabled. Default is false.
   */
  @property({ type: Boolean })
  showTimezone?: boolean = false;

  /**
   * If set to true, then show timezone switch that can hide and show timezone field. Default is true.
   */
  @property({ type: Boolean })
  showTimezoneSwitch?: boolean = true;

  /**
   * If true, then set default timezone to client timezone. Default is true.
   */
  @property({ type: Boolean })
  setDefaultTimezoneToBrowserTimezone?: boolean = true;

  /*
   * Set this property if you want to set timezone switch on at initial load. Default is false.
   */
  @property({ type: Boolean })
  setTimezoneSwitchOnAtInitialLoad?: boolean = false;

  /**
   * timeSelectorDirection (Optional)
   * - **column** Show label and date above selector where label will be in left and date will be in right
   * - **row** Show label and date in left of selector where date will be below label.
   */
  @property()
  timeSelectorDirection?: 'row' | 'column' = 'column';

  /**
   * timeSelectorVariant (Optional)
   * - **primary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   */
  @property()
  timeSelectorVariant?: 'primary' | 'secondary' = 'primary';

  /**
   * multiYear (Optional)
   * Amount of years to go before and after the current date. Don't specify it in case your don't want to restrict minimum or maximum month
   */
  @state()
  multiYear?: number = 3;

  @property()
  previousButtonText = 'Previous Month';

  @property()
  nextButtonText = 'Next Month';

  /**
   * Month gap with which range calendar will open. It should be greater or equal to 1. Default is 1.
   */
  @property({ type: Number })
  rangeMonthGap?: number = 1;

  /**
   * Container for current days in month
   */
  @property({ type: Array })
  days: Array<any> = [];

  /**
   * Container for years toggle
   */
  @property({ type: Array })
  years: Array<any> = [];

  /**
   * Start Selected date
   */
  @property()
  startSelectedDate: string = '';

  /**
   * End Selected date. Don't set it if range selection is disabled
   */
  @property()
  endSelectedDate: string = '';

  /**
   * Start Selected time. Specify in HH;mm;ss format.
   */
  @property()
  startSelectedTime: string = '';

  /**
   * End Selected time. Specify in HH;mm;ss format.
   */
  @property()
  endSelectedTime: string = '';

  /**
   * Default is false. If true hide seconds field.
   */
  @property({ type: Boolean })
  hideSecondsField?: boolean = false;

  /*
   * Timezone string
   */
  @property()
  timezone?: string = '';

  /**
   * If true than reset data will not be determined by initial start and end date
   */
  @property({ type: Boolean })
  determineResetDateFromInitialDate?: boolean = true;

  /**
   * Initialize it in case reset start date is different from initialSelectedDate. This property is relevant only if `determineResetDateFromInitialDate` is true
   */
  @property()
  resetSelectedDate?: string = '';

  /**
   * To initialize intial end selected date. Relevant only in case of range selection. This property is relevant only if `determineResetDateFromInitialDate` is true
   */
  @property()
  resetSelectedEndDate?: string = '';

  /**
   * Reset Start Selected time. Specify in HH;mm;ss format. Relevant only if `determineResetDateFromInitialDate` is false.
   */
  @property()
  resetStartSelectedTime: string = '';

  /**
   * To store the initial reset value of timezone
   */
  @property()
  resetTimezone: string = '';

  /**
   * Reset End Selected time. Specify in HH;mm;ss format. Relevant only if `determineResetDateFromInitialDate` is false.
   */
  @property()
  resetEndSelectedTime: string = '';

  /**
   * If true then enables range selection, otherwise disable it. Default is false
   */
  @property({ type: Boolean })
  enableRangeSelection?: boolean = false;

  /**
   * Internal ID only for labeling datepicker popup
   */
  @property()
  datepickerId: any;

  /**
   * Specify date format for UI display
   */
  @property()
  dateFormat: 'MMM dd, yyyy' | 'MM/dd/yyyy' | 'dd/MM/yyyy' | 'yyyy/MM/dd' | 'MMM dd yyyy' | 'dd MMM yyyy' = 'MMM dd, yyyy';

  /**
   * enableTimeSelection
   * If true, user can also provide time input. Default is false.
   */
  @property({ type: Boolean })
  enableTimeSelection: boolean = false;

  /**
   * Disabled attribute
   */
  @property({ type: Boolean })
  disabled?: boolean;

  /**
   * Field note icon name
   */
  @property()
  iconName?: string;

  /**
   * Show the day of the week as a short hand, e.g. "M" for Monday
   */
  @property({ type: Boolean })
  isDayShortHand?: boolean = false;

  /**
   * Start the day of the week on Monday
   */
  @property({ type: Boolean })
  startOnMonday?: boolean = false;

  @state()
  showTimeSelector: boolean = false;

  /**
   * Set end date navigation
   */
  @state()
  navEndDate: any;

  /**
   * Query the left range calendar
   */
  @query('#en-left-range-calendar')
  leftRangeCalendar: ENRangeCalendar;

  /**
   * Query the right range calendar
   */
  @query('#en-right-range-calendar')
  rightRangeCalendar: ENRangeCalendar;

  @state()
  disableReset: boolean = true;

  private _defaultStartSelectedDate: string = '';
  private _defaultEndSelectedDate: string = '';
  private _defaultStartSelectedTime: string = '';
  private _defaultEndSelectedTime: string = '';
  private _defaultNavDate: Date;
  private _defaultNavEndDate: Date;

  /**
   * Connected callback lifecycle
   * 1. Get initial value of currentDate
   * 2. Set calendar grid based on end date month and year
   * 3. Set calendar grid based on current date month and year
   */
  connectedCallback() {
    super.connectedCallback();
  }

  /**
   * Initialize default values
   * 1. Enable time toggle if there is any initial time value
   */
  firstUpdated() {
    if (!this.enableTimeSelection) {
      this.showTimeSelector = false;
    }

    setTimeout(() => {
      const startDate = new Date(this.startSelectedDate);
      const endDate = new Date(this.endSelectedDate);
      if (isValid(startDate)) {
        this.navDate = startOfMonth(startDate);
      } else if (isValid(endDate)) {
        this.navDate = startOfMonth(sub(endDate, { months: this.rangeMonthGap }));
      } else {
        this.navDate = startOfMonth(this.currentDate);
      }
      const dateAfterOneMonth = add(this.navDate, { months: this.rangeMonthGap });
      /* 1 */
      this.navEndDate = startOfMonth(dateAfterOneMonth);
      this._defaultNavDate = this.navDate;
      this._defaultNavEndDate = this.navEndDate;

      this._defaultStartSelectedDate = this.startSelectedDate;
      this._defaultEndSelectedDate = this.endSelectedDate;
      this._defaultStartSelectedTime = this.startSelectedTime;
      this._defaultEndSelectedTime = this.endSelectedTime;
      if (!!this.startSelectedTime || !!this.endSelectedTime) {
        /* 1 */
        this.showTimeSelector = true;
      }
      if (this.enableTimeSelection && this.showTimezone) {
        if (this.resetTimezone === '') {
          this.resetTimezone = this.timezone;
        } else {
          this.timezone = this.resetTimezone;
        }
      }

      this._setDisableResetButtonStatus();
    }, 0);
  }

  private _setDisableResetButtonStatus() {
    if (this.determineResetDateFromInitialDate) {
      this.disableReset =
        this.startSelectedDate === this._defaultStartSelectedDate &&
        this.endSelectedDate === this._defaultEndSelectedDate &&
        this.startSelectedTime === this._defaultStartSelectedTime &&
        this.endSelectedTime === this._defaultEndSelectedTime;
    } else {
      this.disableReset =
        this.startSelectedDate === this.resetSelectedDate &&
        this.endSelectedDate === this.resetSelectedEndDate &&
        this.startSelectedTime === this.resetStartSelectedTime &&
        this.endSelectedTime === this.resetEndSelectedTime;
    }
    if (this.disableReset && this.enableTimeSelection && this.showTimezone) {
      this.disableReset = this.disableReset && this.resetTimezone === this.timezone;
    }
  }

  public handleOnChange(eventName: string, eventDetail: any = null) {
    if (eventDetail) {
      this.dispatch({
        eventName: eventName,
        detailObj: eventDetail
      });
    } else {
      let timeAttributes: { startTime?: string; endTime?: string } = {};
      if (this.enableTimeSelection) {
        timeAttributes = { startTime: this.startSelectedTime };
        if (this.enableRangeSelection) {
          timeAttributes.endTime = this.endSelectedTime;
        }
      }
      const startDate = new Date(this.startSelectedDate);
      if (isValid(startDate)) {
        startDate.setHours(0, 0, 0, 0);
      }
      const endDate = new Date(this.endSelectedDate);
      if (isValid(endDate)) {
        endDate.setHours(0, 0, 0, 0);
      }
      this.dispatch({
        eventName,
        detailObj: {
          startDate: isValid(startDate) ? format(startDate, this.dateFormat) : '',
          rawStartDate: this.startSelectedDate,
          endDate: isValid(endDate) ? format(endDate, this.dateFormat) : '',
          rawEndDate: this.endSelectedDate,
          ...timeAttributes,
          timezone: this.timezone ?? ''
        }
      });
    }
  }

  handleChange(evt: CustomEvent, setNavigation = false) {
    const rawStartDate = evt.detail.rawStartDate;
    this.startSelectedDate = rawStartDate;
    const rawEndDate = evt.detail.rawEndDate;
    this.endSelectedDate = rawEndDate;
    if (setNavigation) {
      const startDate = new Date(this.startSelectedDate);
      const endDate = new Date(this.endSelectedDate);
      if (isValid(startDate)) {
        this.navDate = startOfMonth(startDate);
      } else if (isValid(endDate)) {
        this.navDate = startOfMonth(sub(endDate, { months: this.rangeMonthGap }));
      } else {
        this.navDate = startOfMonth(this.currentDate);
      }
      const dateAfterOneMonth = add(this.navDate, { months: this.rangeMonthGap });
      this.navEndDate = startOfMonth(dateAfterOneMonth);
    }
    this._setDisableResetButtonStatus();
    this.handleOnChange('change');
  }

  /**
   * Handle Reset calendar
   */
  handleReset() {
    if (this.determineResetDateFromInitialDate) {
      this.startSelectedDate = this._defaultStartSelectedDate;
      this.endSelectedDate = this._defaultEndSelectedDate;
      this.startSelectedTime = this._defaultStartSelectedTime;
      this.endSelectedTime = this._defaultEndSelectedTime;
      this.navDate = this._defaultNavDate;
      this.navEndDate = this._defaultNavEndDate;
    } else {
      this.startSelectedDate = this.resetSelectedDate;
      this.endSelectedDate = this.resetSelectedEndDate;
      this.startSelectedTime = this.resetStartSelectedTime;
      this.endSelectedTime = this.resetEndSelectedTime;
      this.navDate = startOfMonth(!!this.resetSelectedDate ? new Date(this.resetSelectedDate) : new Date());
      const dateAfterOneMonth = add(this.navDate, { months: this.rangeMonthGap });
      this.navEndDate = startOfMonth(dateAfterOneMonth);
    }
    if (this.enableTimeSelection && this.showTimezone) {
      this.timezone = this.resetTimezone;
    }
    this._setDisableResetButtonStatus();
    this.handleOnChange('reset');
  }

  handleTimeToggle() {
    if (!this.enableTimeSelection) {
      return false;
    }
    this.showTimeSelector = !this.showTimeSelector;
    if (this.showTimeSelector) {
      setTimeout(() => {
        const timeSelector = this.shadowRoot.querySelector<HTMLElement>('.en-c-calendar__time-selector--selector');
        if (timeSelector) {
          timeSelector.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
    this.dispatch({
      eventName: 'time-toggle',
      detailObj: {
        startTime: this.startSelectedTime,
        endTime: this.endSelectedTime
      }
    });
  }

  handleTimeSelectorChange(evt: CustomEvent, type: 'start' | 'end' = 'start') {
    if (evt.stopPropagation) {
      evt.stopPropagation();
    }

    const detail = evt.detail;
    const isTimeNotEmpty = !!`${detail.hours}` || !!`${detail.minutes}` || !!`${detail.seconds}`;
    if (type === 'start') {
      this.startSelectedTime = isTimeNotEmpty ? `${detail.hours || ''}:${detail.minutes || ''}:${detail.seconds || ''}` : '';
    } else {
      this.endSelectedTime = isTimeNotEmpty ? `${detail.hours || ''}:${detail.minutes || ''}:${detail.seconds || ''}` : '';
    }
    this._setDisableResetButtonStatus();
    this.handleOnChange('change');
  }

  private _handleTimezoneSelection(evt: CustomEvent) {
    const { timezone } = evt.detail;
    if (!timezone) {
      this.timezone = undefined;
    } else {
      this.timezone = timezone;
    }
    if (this.setDefaultTimezoneToBrowserTimezone && this.resetTimezone === '') {
      this.resetTimezone = this.timezone;
    }
    this._setDisableResetButtonStatus();
    this.handleOnChange('change');
  }

  /**
   * Handle Done button click on calendar
   */
  handleDone() {
    this.handleOnChange('done');
  }

  handlePrevEndClick(evt: CustomEvent) {
    this.navEndDate = evt.detail.navDate;
  }

  handlePrevStartClick(evt: CustomEvent) {
    this.navDate = evt.detail.navDate;
    if (this.rightRangeCalendar) {
      this.rightRangeCalendar.setPrevMonth();
    }
  }

  handleNextEndClick(evt: CustomEvent) {
    this.navEndDate = evt.detail.navDate;
    if (this.leftRangeCalendar) {
      this.leftRangeCalendar.setNextMonth();
    }
  }

  handleNextStartClick(evt: CustomEvent) {
    this.navDate = evt.detail.navDate;
  }

  /**
   * 1. Adding one day in week, month and quarter because today day is not excluded but It has to be included.
   * @param type
   */
  handleChipClick(type: 'week' | 'month' | 'quarter' = 'week') {
    const todayDate = new Date(this.currentDate);
    todayDate.setHours(0, 0, 0, 0);
    let chipSet = false;
    if (type === 'week') {
      let weekBeforeDate = sub(todayDate, { weeks: 1 });
      /* 1 */
      weekBeforeDate = add(weekBeforeDate, { days: 1 });
      weekBeforeDate.setHours(0, 0, 0, 0);
      this.startSelectedDate = weekBeforeDate.toISOString();
      this.endSelectedDate = todayDate.toISOString();
      chipSet = true;
    } else if (type === 'month') {
      const subOb = this.filterChipVariant === 'absolute' ? { days: 30 } : { months: 1 };
      let monthBeforeDate = sub(todayDate, subOb);
      monthBeforeDate = add(monthBeforeDate, { days: 1 });
      monthBeforeDate.setHours(0, 0, 0, 0);
      this.startSelectedDate = monthBeforeDate.toISOString();
      this.endSelectedDate = todayDate.toISOString();
      chipSet = true;
    } else if (type === 'quarter') {
      const subOb = this.filterChipVariant === 'absolute' ? { days: 90 } : { months: 3 };
      let monthBeforeDate = sub(todayDate, subOb);
      monthBeforeDate = add(monthBeforeDate, { days: 1 });
      monthBeforeDate.setHours(0, 0, 0, 0);
      this.startSelectedDate = monthBeforeDate.toISOString();
      this.endSelectedDate = todayDate.toISOString();
      chipSet = true;
    }
    if (chipSet) {
      this.navDate = startOfMonth(todayDate);
      this.navEndDate = startOfMonth(add(this.navDate, { months: 1 }));
      this._setDisableResetButtonStatus();
      this.handleOnChange('change');
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-double-range-calendar', {
      'en-show-footer': this.showFooter
    });

    const startTimes = this.startSelectedTime ? this.startSelectedTime.split(':') : [];
    const endTimes = this.endSelectedTime ? this.endSelectedTime.split(':') : [];
    const startDate = new Date(this.startSelectedDate);
    if (isValid(startDate)) startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(this.endSelectedDate);
    if (isValid(endDate)) endDate.setHours(0, 0, 0, 0);

    const showChips = this.enableRangeSelection && (this.showLastWeekChip || this.showLastMonthChip || this.showLastQuarterChip);

    return html`
      <div class="${componentClassNames}">
      ${
        showChips
          ? html`<div class="en-c-chips">
        <${this.chipGroupEl} .keepOldTooltip=${this.keepOldTooltip}>
          ${this.showLastWeekChip ? html`<${this.chipEl} .keepOldTooltip=${this.keepOldTooltip} @click=${() => this.handleChipClick('week')} variant="${this.filterChipVariant === 'absolute' ? 'secondary' : 'neutral'}">${this.filterChipVariant === 'absolute' ? '7 Days' : 'Last Week'}</${this.chipEl}>` : html``}
          ${this.showLastMonthChip ? html`<${this.chipEl} .keepOldTooltip=${this.keepOldTooltip} @click=${() => this.handleChipClick('month')} variant="${this.filterChipVariant === 'absolute' ? 'secondary' : 'neutral'}">${this.filterChipVariant === 'absolute' ? '30 Days' : 'Last Month'}</${this.chipEl}>` : html``}
          ${this.showLastQuarterChip ? html`<${this.chipEl} .keepOldTooltip=${this.keepOldTooltip} @click=${() => this.handleChipClick('quarter')} variant="${this.filterChipVariant === 'absolute' ? 'secondary' : 'neutral'}">${this.filterChipVariant === 'absolute' ? '90 Days' : 'Last Quarter'}</${this.chipEl}>` : html``}
        </${this.chipGroupEl}>
      </div>`
          : html``
      }
        <div class="en-c-scroll">
          <div class="calendar-time-view">
            <div class="calendar-view">
              <${this.rangeCalendarEl} class="calendar" @next=${this.handleNextStartClick} @prev=${this.handlePrevStartClick} .rangeMonthGap=${this.rangeMonthGap} id="en-left-range-calendar" @change=${(
                evt: CustomEvent
              ) => {
                evt.preventDefault();
                evt.stopPropagation();
                this.handleChange(evt);
              }} .isDoubleRangeCalendar=${true} .hideBoxShadow=${true} .startSelectedDate=${this.startSelectedDate} .endSelectedDate=${this.endSelectedDate} .enableRangeSelection=${this.enableRangeSelection} .currentDate=${isValid(new Date(this.navDate)) ? new Date(this.navDate).toISOString() : new Date().toISOString()} .todayCurrentDate=${this.currentDate} .multiYear=${this.multiYear} dateFormat=${this.dateFormat} .disabled=${this.disabled} iconName=${this.iconName} .isDayShortHand=${this.isDayShortHand} .startOnMonday=${this.startOnMonday} .removeNextNavigation=${true} .centerHeaderView=${true} previousButtonText=${this.previousButtonText} disabledMaxDate=${this.disabledMaxDate} disabledMinDate=${this.disabledMinDate} .showFooter=${false}></${this.rangeCalendarEl}>
              <${this.rangeCalendarEl} class="calendar" @prev=${this.handlePrevEndClick} @next=${this.handleNextEndClick} .rangeMonthGap=${this.rangeMonthGap} id="en-right-range-calendar" @change=${(
                evt: CustomEvent
              ) => {
                evt.preventDefault();
                evt.stopPropagation();
                this.handleChange(evt);
              }} .isDoubleRangeCalendar=${true} .hideBoxShadow=${true} .startSelectedDate=${this.startSelectedDate} .endSelectedDate=${this.endSelectedDate} .enableRangeSelection=${this.enableRangeSelection} .currentDate=${isValid(new Date(this.navEndDate)) ? new Date(this.navEndDate).toISOString() : new Date().toISOString()} .todayCurrentDate=${this.currentDate} .multiYear=${this.multiYear} dateFormat=${this.dateFormat} .disabled=${this.disabled} iconName=${this.iconName} .isDayShortHand=${this.isDayShortHand} .startOnMonday=${this.startOnMonday} .removePrevNavigation=${true} .centerHeaderView=${true} nextButtonText=${this.nextButtonText} disabledMaxDate=${this.disabledMaxDate} disabledMinDate=${this.disabledMinDate} .showFooter=${false}></${this.rangeCalendarEl}>
            </div>
            ${
              this.enableTimeSelection
                ? html`<div class="en-c-calendar__time-selector">
                <div class="en-c-calendar__time-selector--toggle">
                  <label for="time-toggle-switch">${this.enableRangeSelection ? 'Start and End Time' : 'Time'}</label>
                  <${this.switchEl} id="time-toggle-switch" .isChecked=${this.showTimeSelector} @changed=${this.handleTimeToggle} label="${this.enableRangeSelection ? 'Start and End Time' : 'Time'}" variant="primary" name="time-toggle"></${this.switchEl}>
                </div>
                ${
                  this.showTimeSelector
                    ? html`<div class="en-c-calendar__time-selector--selector">
                      <${this.inlineTimeSelectorEl} .hideSecondsField=${this.hideSecondsField} direction=${this.timeSelectorDirection} variant=${this.timeSelectorVariant} dropdownAlign="top" label="${this.enableRangeSelection ? 'Start Time' : 'Select Time'}" datePlaceholder="${isValid(startDate) ? format(startDate, 'yyyy/MM/dd') : ''}" hours=${startTimes[0] || ''} minutes=${startTimes[1] || ''} seconds=${startTimes[2] || ''} @change=${(evt: CustomEvent) => this.handleTimeSelectorChange(evt, 'start')} ></${this.inlineTimeSelectorEl}>
                      ${this.enableRangeSelection ? html`<${this.inlineTimeSelectorEl} .hideSecondsField=${this.hideSecondsField} direction=${this.timeSelectorDirection} variant=${this.timeSelectorVariant} dropdownAlign="top" label="End Time" datePlaceholder="${isValid(endDate) ? format(endDate, 'yyyy/MM/dd') : ''}" hours=${endTimes[0] || ''} minutes=${endTimes[1] || ''} seconds=${endTimes[2] || ''} @change=${(evt: CustomEvent) => this.handleTimeSelectorChange(evt, 'end')} ></${this.inlineTimeSelectorEl}>` : html``}
                      ${
                        this.showTimezone
                          ? html`<${this.timezoneDropdownEl} .showTimezoneSwitch=${this.showTimezoneSwitch} .setTimezoneSwitchOnAtInitialLoad=${this.setTimezoneSwitchOnAtInitialLoad} .setDefaultTimezoneToBrowserTimezone=${this.setDefaultTimezoneToBrowserTimezone}  .enableDynamicPositioning=${false} variant="${this.timeSelectorVariant}" dropdownAlign="top" timezone=${this.timezone ?? ''} @timezoneChange=${
                              this._handleTimezoneSelection
                            }>
            </${this.timezoneDropdownEl}>`
                          : html``
                      }
                </div>`
                    : html``
                }
            </div>`
                : html``
            }
          </div>
          ${
            this.showFooter
              ? html`<div class="en-c-calendar__footer">
                <${this.buttonEl} class="en-c-calendar__footer--left" variant="tertiary" @click=${this.handleReset} .assignThemeToTertiary=${true} ?isDisabled=${this.forceDisableResetButton || this.disableReset}>Reset to Default</${this.buttonEl}>
                ${!this.hideDone ? html`<${this.buttonEl} .isDisabled=${this.disableDoneButton} class="en-c-calendar__footer--right" variant="primary" @click=${this.handleDone}>Done</${this.buttonEl}>` : html``}
          </div>`
              : html``
          }
        </div>
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENDoubleRangeCalendar.el) === undefined) {
  customElements.define(ENDoubleRangeCalendar.el, ENDoubleRangeCalendar);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-double-range-calendar': ENDoubleRangeCalendar;
  }
}
