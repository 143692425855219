import { TemplateResult, unsafeCSS } from 'lit';
import { property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENButton } from '../button/button';
import { ENHeading } from '../heading/heading';
import { ENIconArrowLeft } from '../icon/icons/arrow-left';
import { ENIconClose } from '../icon/icons/close';
import { ENIconMaximize } from '../icon/icons/maximize';
import { ENIconMinimize } from '../icon/icons/minimize';
import styles from './dialog.scss';

/**
 * Component: en-dialog
 * @slot - The main body of the dialog
 * @slot "header" - The header of the dialog that appears above the main slot
 * @slot "footer" - The footer of the dialog that appears below the main slot
 */
export class ENDialog extends ENElement {
  static el = 'en-dialog';

  private elementMap = register({
    elements: [
      [ENHeading.el, ENHeading],
      [ENButton.el, ENButton],
      [ENIconArrowLeft.el, ENIconArrowLeft],
      [ENIconClose.el, ENIconClose],
      [ENIconMinimize.el, ENIconMinimize],
      [ENIconMaximize.el, ENIconMaximize]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private headingEl = unsafeStatic(this.elementMap.get(ENHeading.el));
  private buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));
  private iconCloseEl = unsafeStatic(this.elementMap.get(ENIconClose.el));
  private iconMinimizeEl = unsafeStatic(this.elementMap.get(ENIconMinimize.el));
  private iconMaximizeEl = unsafeStatic(this.elementMap.get(ENIconMaximize.el));
  private iconArrowLeftEl = unsafeStatic(this.elementMap.get(ENIconArrowLeft.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Heading text that appears in the header region
   */
  @property()
  heading?: string;

  /**
   * SubTitle that appears below the header title
   */
  @property()
  subTitle?: string = "";

  /**
   * Is active?
   * - **true** Shows the dialog container
   * - **false** Hides the dialog container
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * partitions
   * - Number of partitions in which dialog body content will be divided. Min is 2 and Max is 3.
   */
  @property({ type: Number })
  partitions?: number;

  /**
   * Aria Labelled By attribute
   * - Dynamically set for A11y
   */
  @property()
  ariaLabelledBy?: string;

  /**
   * Has backdrop?
   * - **true** Displays a dimmed background behind the dialog container
   * - **false** Hides the dimmed background behind the dialog container
   */
  @property({ type: Boolean })
  hasBackdrop?: boolean;

  /**
   * Show Back Icon button?
   * - **true** Displays a back button before heading
   * - **false** Hides back button
   * Default is false
   */
  @property({ type: Boolean })
  showBackIconButton?: boolean = false;

  /**
   * If true, then dialog will be minimizable. Otherwise it will not be minimizable. Default is false.
   */
  @property({ type: Boolean })
  isMinimizable?: boolean = false;

  /**
   * If true, dialog will open minimized by default. Otherwise it will be maximized by default. Default value is false.
   */
  @property({ type: Boolean })
  keepMinimizedByDefault?: boolean = false;

  /**
   * Position at which to minimize. Default is bottom-right.
   */
  @property()
  minimizePosition?: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right' = 'bottom-right';

  /**
   * backIconButton icon size
   * - **sm** renders size of 16px
   * - **md** renders a larger size than sm (20px)
   * - **lg** renders a larger size than the md variant (24px)
   * - **xl** renders a larger size than the lg variant (32px)
   * Default is lg
   */
  @property({ type: String })
  backIconButtonSize: 'sm' | 'md' | 'lg' | 'xl' = 'lg';

  /**
   * Disable click outside
   * - **true** Disables closing the dialog on click outside of the dialog container
   * - **false** Enables closing the dialog on click outside of the dialog container
   * Default is false. NOTE: If user click on open dialog button then they will able to close it. If you want to disable open dialog button as well, you have to set hasBackdrop to true.
   */
  @property({ type: Boolean })
  disableClickOutside?: boolean = false;

  /**
   * If set to true, then hide scroll on body if dialog is active. Default is false.
   */
  @property({ type: Boolean })
  hideBodyOverflowIfDialogIsActive = false;

  /**
   * Disable cross icon
   * - **true** Disable cross icon
   * - **false** Enables cross icon
   * Default is false.
   */
  @property({ type: Boolean })
  disableCrossIcon?: boolean = false;

  /**
   * Hide cross icon
   * - **true** Hide cross icon
   * - **false** Show cross icon
   * Default is false.
   */
  @property({ type: Boolean })
  hideCrossIcon?: boolean = false;

  /**
   * The width of the dialog container
   * - If no value is entered, it defaults to 432px
   */
  @property({ type: Number })
  width?: number;

  /**
   * Query the dialog container
   */
  @query('.en-c-dialog__container')
  dialogContainer: HTMLElement;

  /**
   * Query the dialog trigger
   */
  @queryAssignedElements({ slot: 'trigger' })
  dialogTrigger: any[];

  /* If true, dialog will be minimized, else dialog will be expanded */
  @state()
  private _isMinimized?: boolean = false;

  /**
   * Query the dialog trigger inner element
   */
  get dialogTriggerButton(): any {
    if (this.dialogTrigger[0] && this.dialogTrigger[0].shadowRoot) {
      return this.dialogTrigger[0].shadowRoot.querySelector('*');
    }
  }

  /**
   * Initialize functions
   */
  constructor() {
    super();
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
  }

  /**
   * Connected callback lifecycle
   * 1. Add mousedown event listener
   * 2. Add focus event listener
   */
  connectedCallback() {
    super.connectedCallback();
    globalThis.addEventListener('mousedown', this.handleOnClickOutside, false); /* 1 */
    globalThis.addEventListener('keydown', this.handleTabKeyDown, false); /* 2 */
  }

  /**
   * Disconnected callback lifecycle
   * 1. Remove mousedown event listener
   * 2. Remove focus event listener
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    globalThis.removeEventListener('mousedown', this.handleOnClickOutside, false); /* 1 */
    globalThis.removeEventListener('keydown', this.handleTabKeyDown, false);
  }

  /**
   * First updated lifecycle
   * 1. Wait for slotted components to be loaded
   * 2. Set aria-expanded on the trigger for A11y
   * 4. If dialog is minimzable then update initial _isMinimized value
   */
  async firstUpdated() {
    await this.updateComplete; /* 1 */
    this.setAria(); /* 2 */
    this.setWidth(); /* 3 */
    /* 4 */
    if (this.isMinimizable) {
      this._isMinimized = this.keepMinimizedByDefault;
    }
  }

  /**
   * Updated lifecycle
   * 1. Update aria-expanded on the trigger based on if isActive
   */
  updated() {
    this.setAria();
  }

  /**
   * Set the width
   * 1. Add a custom property to adjust the width of the dialog container
   */
  setWidth() {
    if (this.width) {
      this.style.setProperty('--en-dialog-container-width', this.width.toString() + 'px');
    }
  }

  /**
   * Set aria-expanded to the trigger button
   * 1. Dynamically sets the aria-labelledby for A11y
   * 2. Set isExpanded to this.isActive if it's truthy, otherwise, set it to false
   */
  setAria() {
    /* 1 */
    this.ariaLabelledBy = this.ariaLabelledBy || nanoid();
    /* 2 */
    if (this.dialogTriggerButton) {
      this.dialogTriggerButton.isExpanded = this.isActive || false;
    }
  }

  /**
   * Handles the click event outside the component:
   * 1. Check if the dialog is active and disableClickOutside is not true
   * 2. Determine if the click occurred inside the active dialog container
   * 3. Check if the click occurred outside the active dialog
   * 4. Close the dialog if the click occurred outside it
   */
  handleOnClickOutside(event: MouseEvent) {
    /* 1 */
    if (this.isActive && !this.disableClickOutside) {
      const didClickInside = event.composedPath().includes(this.dialogContainer); /* 2 */
      /* 3 */
      if (!didClickInside) {
        /* 4 */
        this.close();
      }
    }
  }

  /**
   * Handle focus so as to ensure that when dialog open with backdrop open, focus should remain within dialog.
   * @param event
   */
  handleTabKeyDown = (event: KeyboardEvent) => {
    if (!this.isActive || !this.hasBackdrop) return false;
    if (event.key === 'Tab') {
      const shift = event.shiftKey;
      if (shift) {
        if (this.shadowRoot.activeElement === this.dialogContainer) {
          // shift-tab pressed on dialog container
          event.preventDefault();
        }
      }
    }
  };

  /**
   * Handle on keydown events
   * 1. If the dialog is open and escape is keyed, close the dialog and return focus to the trigger button
   */
  handleOnKeydown(e: KeyboardEvent) {
    /* 1 */
    if (this.isActive === true && e.code === 'Escape') {
      this.close();
    }
  }

  /**
   * Handle on click of close button
   * 1. Toggle the active state between true and false
   * 2. Dispatch a custom event on click of close button
   */
  handleOnCloseButton() {
    this.toggleActive();
    this.dispatch({
      eventName: 'dialogCloseButton',
      detailObj: {
        active: this.isActive,
        item: this
      }
    });
  }

  /**
   * Set dialog active state
   * 1. Toggle the active state between true and false
   * 2. Open/close the dialog container based on isActive
   */
  public toggleActive() {
    this.isActive = !this.isActive; /* 1 */

    /* 2 */
    if (this.isActive) {
      this.open();
    } else {
      this.close();
    }
  }

  /**
   * Open dialog
   * 1. Set isActive to true to show the dialog
   * 2. Focus on the dialog container once opened. Timeout is equal to the css transition timing
   * 3. Dispatch a custom event on open
   */
  public open() {
    this.isActive = true; /* 1 */
    if (this.hideBodyOverflowIfDialogIsActive) {
      const body = document.body;
      if (body.style.overflow !== 'hidden') {
        body.style.overflow = 'hidden';
      }
    }
    setTimeout(() => {
      this.dialogContainer.focus(); /* 2 */
    }, 400);
    /* 3 */
    this.dispatch({
      eventName: 'dialogOpen',
      detailObj: {
        active: this.isActive,
        item: this
      }
    });
  }

  /**
   * Close dialog
   * 1. Set isActive to false to hide the dialog
   * 2. Set the focus on trigger button element when the dialog is closed
   * 3. Dispatch a custom event on close
   * 4. For minimzable dialog set _isMinimzed state equal to `keepMinimizedByDefault`
   */
  public close() {
    this.isActive = false; /* 1 */
    if (this.hideBodyOverflowIfDialogIsActive) {
      const body = document.body;
      if (body.style.overflow === 'hidden') {
        body.style.overflow = '';
      }
    }
    const body = document.body;
    if (body.style.overflow === 'hidden') {
      body.style.overflow = '';
    }
    /* 2 */
    if (this.dialogTriggerButton) {
      setTimeout(() => {
        this.dialogTriggerButton.focus();
      }, 1);
    }
    /* 4 */
    if (this.isMinimizable) {
      this._isMinimized = this.keepMinimizedByDefault;
    }
    /* 3 */
    this.dispatch({
      eventName: 'dialogClose',
      detailObj: {
        active: this.isActive,
        item: this
      }
    });
  }

  /**
   * Handle Minimized button click
   * @returns
   */
  private _handleMinimizeClick = () => {
    if (!this.isMinimizable) return;
    this._isMinimized = !this._isMinimized;
    this.dispatch({ eventName: 'minimizeClick', detailObj: { isMinimized: this._isMinimized } });
  };

  /**
   * Event handler for back button click. It will return false and will not dispatch event if showBackIconButton is false
   * @param evt Click Event
   * @returns
   */
  handleBackButtonClick = (evt: MouseEvent) => {
    if (!this.showBackIconButton) return false;
    this.dispatch({ eventName: 'backButtonClick', detailObj: { clickEvt: evt, dialog: this } });
  };

  render() {
    const componentClassNames = this.componentClassNames('en-c-dialog', {
      'en-is-active': this.isActive === true,
      'en-has-backdrop': this.hasBackdrop === true
    });

    const showPartitions = this.partitions && this.partitions > 1 && this.partitions < 4;

    /* NOTE: Any change in dialog header that leads to change in header height,
     also requires adjustment in CSS rule of class .en-minimized-container */

    return html`
      <div class="${componentClassNames}" tabindex="${this.hasBackdrop === true && this.isActive === true ? -1 : 0}" @keydown=${this.handleOnKeydown}>
        ${this.slotNotEmpty('trigger') &&
        html`
          <div
            class="en-c-dialog__trigger"
            @click=${() => {
              if (this.isActive && this.disableClickOutside && this.hasBackdrop) {
                return false;
              }
              this.toggleActive();
            }}
          >
            <slot name="trigger"></slot>
          </div>
        `}
        <div
          class=${classMap({
            'en-c-dialog__container': true,
            'en-minimized-container': this.isMinimizable && this._isMinimized,
            'en-top-left': this.minimizePosition === 'top-left',
            'en-top-center': this.minimizePosition === 'top-center',
            'en-top-right': this.minimizePosition === 'top-right',
            'en-bottom-left': this.minimizePosition === 'bottom-left',
            'en-bottom-center': this.minimizePosition === 'bottom-center',
            'en-bottom-right': this.minimizePosition === 'bottom-right'
          })}
          tabindex=${this.isActive ? '0' : '-1'}
          role="dialog"
          aria-labelledby=${this.ariaLabelledBy}
          aria-hidden=${this.isActive ? false : true}
        >
          <div class="en-c-dialog__header">
            ${this.showBackIconButton
              ? html`<${this.buttonEl} variant="quaternary" @click=${this.handleBackButtonClick} .hideText=${true}>
                <${this.iconArrowLeftEl} slot="before" size="${this.backIconButtonSize}"></${this.iconArrowLeftEl}>
                Back
              </${this.buttonEl}>`
              : html``}
            ${(this.slotNotEmpty('header') || this.heading) &&
            html`
              <div class="en-c-dialog__title" id=${this.ariaLabelledBy}>
                ${this.heading &&
                html`
                  <${this.headingEl} tagName="h3">${this.heading}</${this.headingEl}>
                `}
                <slot name="header"></slot>
              </div>
            `}
            ${this.isMinimizable
              ? html`<${this.buttonEl} class="en-minimize-dialog-btn" .hideText=${true} variant="quaternary" @click=${this._handleMinimizeClick}>${this._isMinimized ? html`<${this.iconMaximizeEl} slot="before"></${this.iconMaximizeEl}>` : html`<${this.iconMinimizeEl} slot="before"></${this.iconMinimizeEl}>`}</${this.buttonEl}>`
              : html``}
            ${!this.hideCrossIcon
              ? html`<${this.buttonEl} .isDisabled=${this.disableCrossIcon} class="en-c-dialog__close-button" variant="quaternary" ?hideText=${true} @click=${this.handleOnCloseButton}>
            Close
            <${this.iconCloseEl} class="en-c-dialog__icon-close" slot="after"></${this.iconCloseEl}>
          </${this.buttonEl}>`
              : html``}
          </div>
          ${this.subTitle &&
            html`
              <div class="en-c-dialog__sub-title">
                ${this.subTitle}
              </div>
            `}
          <div
            class=${classMap({ 'en-c-dialog__body': true, 'en-is-partition': showPartitions, 'en-hide': this.isMinimizable && this._isMinimized })}
          >
            ${!showPartitions
              ? html`<slot></slot>`
              : this.partitions === 2
                ? html`<slot name="partition1"></slot><en-divider variant="vertical"></en-divider><slot name="partition2"></slot>`
                : html`<slot name="partition1"></slot><en-divider variant="vertical"></en-divider><slot name="partition2"></slot
                    ><en-divider variant="vertical"></en-divider><slot name="partition3"></slot>`}
          </div>
          ${this.slotNotEmpty('footer') &&
          html`
            <div class=${classMap({ 'en-c-dialog__footer': true, 'en-hide': this.isMinimizable && this._isMinimized })}>
              <slot name="footer"></slot>
            </div>
          `}
        </div>
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENDialog.el) === undefined) {
  customElements.define(ENDialog.el, ENDialog);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-dialog': ENDialog;
  }
}
