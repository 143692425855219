import { html, unsafeCSS } from 'lit';
import { property, queryAssignedElements, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { nanoid } from 'nanoid';
import { ENElement } from '../ENElement';
import styles from './contextual-menu.scss';

/**
 * Component: en-contextual-menu
 * - The contextual menuis a component that is opened/closed by a trigger and is positioned around it.
 * @slot - The main body of the contextual menu
 * @slot "trigger" - The trigger that opens/closes the contextual menu
 */
export class ENContextualMenu extends ENElement {
  static el = 'en-contextual-menu';

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Heading text that appears in the header region
   */
  @property()
  heading?: string;

  /**
   * Positions the dropdown contextual menuabsolutely to the trigger.
   * - **default** places the contextual menuto the bottom left
   * - **bottom-center** places the contextual menuto the bottom center
   * - **bottom-right** places the contextual menuto the bottom right
   * - **bottom-left** places the contextual menuto the bottom left
   * - **top-center** places the contextual menuto the top center
   * - **top-right** places the contextual menuto the top right
   * - **top-left** places the contextual menuto the top left
   * - **left** places the contextual menuto the left
   * - **right** places the contextual menuto the right
   */
  @property()
  position?: 'bottom-center' | 'bottom-right' | 'bottom-left' | 'top-center' | 'top-right' | 'top-left' | 'left' | 'right' = 'bottom-left';

  /**
   * Is active?
   * - **true** Shows the contextual menucontainer
   * - **false** Hides the contextual menucontainer
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * Is dismissible?
   * - **true** Shows the contextual menuclose button
   * - **false** Hides the contextual menuclose button
   */
  @property({ type: Boolean })
  isDismissible?: boolean;

  /**
   * Width property
   * - If set, the menu will be constrained to this width in px
   */
  @property({ type: Number })
  width?: number;

  /**
   * Height property
   * - If set, the menu will be constrained to this height in px and enable vertical scrolling
   */
  @property({ type: Number })
  height?: number;

  /**
   * Is set to true when the total height of items in the menu is greater than the menu's height attribute
   * - **true** Displays a scrollbar to handle vertical overflow
   */
  @state()
  hasOverflow?: boolean = false;

  /**
   * Aria Labelled By attribute
   * - Dynamically set for A11y
   */
  @property()
  ariaLabelledBy?: string;

  /**
   * Default is absolute
   * If absolute menu is not floating, then developer can try setting cssPosition to fixed.
   * But setting cssPosition to fixed will work only if parent or ancestor element has not fixed on it.
   */
  @property()
  cssPosition?: 'absolute' | 'fixed' = 'absolute';

  /**
   * Id for menu trigger element. This id is mandatory in case of css position fixed
   */
  @property()
  menuTriggerId?: string = '';

  /**
   * Id for menu container. This id is mandatory in case of css position fixed
   */
  @property()
  menuContainerId?: string = '';

  /**
   * Query the contextual menutrigger
   */
  @queryAssignedElements({ slot: 'trigger' })
  contextualMenuTrigger: any[];

  /**
   * Query the contextual menutrigger inner element
   */
  get contextualMenuTriggerButton(): any {
    if (this.contextualMenuTrigger[0]) {
      if (this.contextualMenuTrigger[0].shadowRoot) {
        return this.contextualMenuTrigger[0].shadowRoot.querySelector('*');
      } else {
        return this.contextualMenuTrigger[0].querySelector('*');
      }
    }
  }

  /**
   * Initialize functions
   */
  constructor() {
    super();
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
  }

  /**
   * Connected callback lifecycle
   * 1. Add mousedown event listener
   */
  connectedCallback() {
    super.connectedCallback();
    globalThis.addEventListener('mousedown', this.handleOnClickOutside, false); /* 1 */
  }

  /**
   * Disconnected callback lifecycle
   * 1. Remove mousedown event listener
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    globalThis.removeEventListener('mousedown', this.handleOnClickOutside, false); /* 1 */
  }

  /**
   * First updated lifecycle
   * 1. Wait for slotted components to be loaded
   * 2. Set aria-expanded on the trigger for A11y
   * 3. Dynamically set the menu's width and height and scroll behavior
   */
  async firstUpdated() {
    await this.updateComplete; /* 1 */
    this.setAria(); /* 2 */
    this.setWidthHeight(); /* 3 */
  }

  /**
   * Set menu position for cssPosition fixed considering all the position values.
   *
   */
  private _setMenuForCssPositionFixed() {
    const contextualMenuContainer: HTMLElement = !!this.menuContainerId
      ? this.shadowRoot.getElementById(this.menuContainerId)
      : this.shadowRoot.querySelector('.en-c-contextual-menu__container');
    const contextualTriggerElement: HTMLElement = !!this.menuTriggerId
      ? this.shadowRoot.getElementById(this.menuTriggerId)
      : this.shadowRoot.querySelector('.en-c-contextual-menu__trigger');
    if (contextualTriggerElement && contextualMenuContainer) {
      const triggerElementRect: DOMRect = contextualTriggerElement.getBoundingClientRect();
      const triggerElementWidth = triggerElementRect.width;
      const triggerElementHeight = triggerElementRect.height;
      const triggerElementTop = triggerElementRect.top;
      const triggerElementLeft = triggerElementRect.left;

      const menuElementRect: DOMRect = contextualMenuContainer.getBoundingClientRect();
      const menuElementWidth = menuElementRect.width;
      const menuElementHeight = menuElementRect.height;

      if (this.position === 'left') {
        contextualMenuContainer.style.top = `${triggerElementTop}px`;
        contextualMenuContainer.style.left = `${triggerElementLeft - menuElementWidth}px`;
      } else if (this.position === 'right') {
        contextualMenuContainer.style.top = `${triggerElementTop}px`;
        contextualMenuContainer.style.left = `${triggerElementLeft + triggerElementWidth}px`;
      } else if (this.position === 'top-left') {
        contextualMenuContainer.style.top = `${triggerElementTop - menuElementHeight}px`;
        contextualMenuContainer.style.left = `${triggerElementLeft - menuElementWidth}px`;
      } else if (this.position === 'top-right') {
        contextualMenuContainer.style.top = `${triggerElementTop - menuElementHeight}px`;
        contextualMenuContainer.style.left = `${triggerElementLeft + triggerElementWidth}px`;
      } else if (this.position === 'top-center') {
        contextualMenuContainer.style.top = `${triggerElementTop - menuElementHeight}px`;
        contextualMenuContainer.style.left = `${triggerElementLeft - triggerElementWidth / 2}px`;
      } else if (this.position === 'bottom-center') {
        contextualMenuContainer.style.top = `${triggerElementTop + triggerElementHeight}px`;
        contextualMenuContainer.style.left = `${triggerElementLeft - triggerElementWidth / 2}px`;
      } else if (this.position === 'bottom-right') {
        contextualMenuContainer.style.top = `${triggerElementTop + triggerElementHeight}px`;
        contextualMenuContainer.style.left = `${triggerElementLeft + triggerElementWidth}px`;
      } else if (this.position === 'bottom-left') {
        contextualMenuContainer.style.top = `${triggerElementTop + triggerElementHeight}px`;
        contextualMenuContainer.style.left = `${triggerElementLeft - menuElementWidth}px`;
      }
    }
  }

  /**
   * Updated lifecycle
   * 1. Update aria-expanded on the trigger based on if isActive
   * 2. If cssPosition property is changed and changed value is 'fixed'
   * 3. If position property is changed and cssPosition value is 'fixed'
   * 4. Dynamically set the menu's width and height and scroll behavior
   */
  updated(changedProperties: Map<string, unknown>) {
    /* 1 */
    this.setAria();
    this.setWidthHeight(); /* 4 */
    let setMenuForCssPositionFixed = false;
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'cssPosition' && this.cssPosition !== oldValue && this.cssPosition === 'fixed') {
        /* 2 */
        setMenuForCssPositionFixed = true;
      } else if (propName === 'position' && this.position !== oldValue && this.cssPosition === 'fixed') {
        /* 3 */
        setMenuForCssPositionFixed = true;
      }
    });
    if (setMenuForCssPositionFixed) {
      this._setMenuForCssPositionFixed();
    }
  }

  /**
   * Set the width and height
   * 1. Add a custom property to adjust the width of the menu panel
   * 2. Add a custom property to adjust the height of the menu panel and enable scroll
   */
  setWidthHeight() {
    if (this.width) {
      this.style.setProperty('--en-contextual-menu-container-width', this.width.toString() + 'px');
    }
    if (this.height) {
      this.style.setProperty('--en-contextual-menu-container-height', this.height.toString() + 'px');
      this.hasOverflow = true;
    }
  }

  /**
   * Set aria-expanded to the trigger button
   * 1. Dynamically sets the aria-labelledby for A11y
   * 2. Set isExpanded to this.isActive if it's truthy, otherwise, set it to false
   */
  setAria() {
    /* 1 */
    this.ariaLabelledBy = this.ariaLabelledBy || nanoid();
    /* 2 */
    if (this.contextualMenuTriggerButton) {
      this.contextualMenuTriggerButton.isExpanded = this.isActive || false;
    }
  }

  /**
   * Handles the click event outside the component:
   * 1. Check if the contextual menu is active
   * 2. Determine if the click occurred inside the active contextual menu
   * 3. Check if the click occurred outside the active contextual menu
   * 4. Close the contextual menu if the click occurred outside it
   */
  handleOnClickOutside(event: MouseEvent) {
    /* 1 */
    if (this.isActive) {
      const didClickInside = event.composedPath().includes(this.shadowRoot.host); /* 2 */
      /* 3 */
      if (!didClickInside) {
        /* 4 */
        this.close();
      }
    }
  }

  /**
   * Handle on keydown events
   * 1. When the Enter key is pressed on the trigger, open the contextual menu and prevent default button click
   * 2. If the contextual menu is open and escape is keyed, close the contextual menu and return focus to the trigger button
   */
  handleOnKeydown(e: KeyboardEvent) {
    const { target } = e as any;
    /* 1 */
    if (this.slotNotEmpty('trigger') && target.matches('[slot="trigger"]') && e.key === 'Enter') {
      e.preventDefault();
      this.open();
    }
    /* 2 */
    if (this.isActive === true && e.code === 'Escape') {
      this.close();
    }
  }

  /**
   * Set contextual menu active state
   * 1. Toggle the active state between true and false
   * 2. Open/close the contextual menu container based on isActive
   */
  public toggleActive() {
    this.isActive = !this.isActive; /* 1 */

    /* 2 */
    if (this.isActive) {
      this.open();
    } else {
      this.close();
    }
  }

  /**
   * Open contextual menu
   * 1. Set isActive to true to show the contextual menu
   * 2. Focus on the contextual menu container once opened. Timeout is equal to the css transition timing
   * 3. Dispatch a custom event on open
   */
  public open() {
    this.isActive = true; /* 1 */
    if (this.cssPosition === 'fixed') {
      setTimeout(() => {
        this._setMenuForCssPositionFixed();
      }, 0);
    }

    setTimeout(() => {
      const contextualMenuContainer: HTMLElement = !!this.menuContainerId
        ? this.shadowRoot.getElementById(this.menuContainerId)
        : this.shadowRoot.querySelector('.en-c-contextual-menu__container');
      contextualMenuContainer.focus(); /* 2 */
    }, 400);
    /* 3 */
    this.dispatch({
      eventName: 'contextualMenuOpen',
      detailObj: {
        active: this.isActive
      }
    });
  }

  /**
   * Close contextual menu
   * 1. Set isActive to false to hide the contextual menu
   * 2. Set the focus on trigger button element when the contextual menu is closed
   * 3. Dispatch a custom event on close
   */
  public close() {
    this.isActive = false; /* 1 */
    setTimeout(() => {
      if (this.contextualMenuTriggerButton) {
        this.contextualMenuTriggerButton.focus(); /* 2 */
      }
    }, 1);
    /* 3 */
    this.dispatch({
      eventName: 'contextualMenuClose',
      detailObj: {
        active: this.isActive
      }
    });
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-contextual-menu', {
      'en-c-contextual-menu--bottom-center': this.position === 'bottom-center' && this.cssPosition === 'absolute',
      'en-c-contextual-menu--bottom-right': this.position === 'bottom-right' && this.cssPosition === 'absolute',
      'en-c-contextual-menu--bottom-left': this.position === 'bottom-left' && this.cssPosition === 'absolute',
      'en-c-contextual-menu--top-center': this.position === 'top-center' && this.cssPosition === 'absolute',
      'en-c-contextual-menu--top-right': this.position === 'top-right' && this.cssPosition === 'absolute',
      'en-c-contextual-menu--top-left': this.position === 'top-left' && this.cssPosition === 'absolute',
      'en-c-contextual-menu--left': this.position === 'left' && this.cssPosition === 'absolute',
      'en-c-contextual-menu--right': this.position === 'right' && this.cssPosition === 'absolute',
      'en-c-contextual-menu__position--fixed': this.cssPosition === 'fixed',
      'en-has-overflow': this.hasOverflow,
      'en-is-active': this.isActive === true
    });

    return html`
      <div class="${componentClassNames}">
        ${this.slotNotEmpty('trigger') &&
        html`
          <div
            id="${ifDefined(!!this.menuTriggerId ? this.menuTriggerId : undefined)}"
            class="en-c-contextual-menu__trigger"
            @click=${this.toggleActive}
            @keydown=${this.handleOnKeydown}
          >
            <slot name="trigger"></slot>
          </div>
        `}
        <div
          id="${ifDefined(!!this.menuContainerId ? this.menuContainerId : undefined)}"
          class="en-c-contextual-menu__container"
          tabindex=${this.isActive ? '0' : '-1'}
          role="region"
          aria-labelledby=${this.ariaLabelledBy}
          aria-hidden=${this.isActive ? false : true}
          @keydown=${this.handleOnKeydown}
        >
          <div class="en-c-contextual-menu__body">
            <slot></slot>
          </div>
        </div>
      </div>
    `;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENContextualMenu.el) === undefined) {
  customElements.define(ENContextualMenu.el, ENContextualMenu);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-contextual-menu': ENContextualMenu;
  }
}
