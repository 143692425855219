import { arrow, computePosition, ComputePositionConfig, flip, offset, platform, shift, size } from '@floating-ui/dom';
import { offsetParent } from 'composed-offset-position';
import { html, PropertyValues, unsafeCSS } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ENElement } from '../ENElement';
import styles from './floating-tooltip.scss';

/**
 * Component: en-floating-tooltip
 * @slot - The components content
 */
export class ENFloatingTooltip extends ENElement {
  static el = 'en-floating-tooltip';

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Placement of tooltip with respect to anchor element. Default value is top, which means tooltip will be aligned
   * in top center of anchor element. However tooltip may not respect placement setting, based on amount of space available and tooltip content.
   */
  @property()
  placement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'right-start'
    | 'right-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'left-start'
    | 'left-end' = 'top';

  /**
   * To override or extend compute position config. But it is not recommended to use this property.
   */
  @property()
  computePositionConfig?: Partial<ComputePositionConfig> = {};

  /**
   * It will allow to apply size(width) restriction on tooltip. If tooltip container has space available then tooltip will render completly otherwise if applySizeRestriction is true, then content in tooltip will wrap according to container width.
   */
  @property({ type: Boolean })
  applySizeMiddleware?: boolean = false;

  /**
   * Portal make sure that tooltip not adds scroll in parent container. But it can have side-effects. In that case it can be turned off.
   * Default is true
   */
  @property({ type: Boolean })
  usePortal?: boolean = true;

  /**
   * To enable arrow on tooltip. Default is true.
   */
  @property({ type: Boolean })
  hasArrow?: boolean = true;

  /**
   * Tooltip trigger element
   */
  @query('.en-floating-tooltip-trigger')
  private _tooltipTriggerEl: HTMLElement;

  /**
   * Tooltip content element
   */
  @query('.en-floating-tooltip-content')
  private _tooltipContentEl: HTMLElement;

  /**
   * Tooltip arrow element
   */
  @query('.en-floating-tooltip-content > #arrow')
  private _tooltipArrowEl: HTMLElement;

  /**
   * compute position of tooltip according to placement, tooltip content and space available on container and set tooltip accordingly.
   * Apart from this also handle arrow positioning.
   */
  private _updateTooltip = () => {
    const middlewares = [offset(10), flip(), shift({ padding: 5 })];
    if (this.hasArrow) {
      middlewares.push(arrow({ element: this._tooltipArrowEl }));
    }
    if (this.applySizeMiddleware) {
      // Inserting middleware at 2nd position
      middlewares.splice(
        1,
        0,
        size({
          apply({ availableWidth, availableHeight, elements }: any) {
            // Change styles, e.g.
            Object.assign(elements.floating.style, {
              maxWidth: `${Math.max(0, availableWidth)}px`,
              maxHeight: `${Math.max(0, availableHeight)}px`
            });
          }
        })
      );
    }
    computePosition(this._tooltipTriggerEl, this._tooltipContentEl, {
      placement: this.placement,
      middleware: middlewares,
      platform: {
        ...platform,
        getOffsetParent: (element) => platform.getOffsetParent(element, offsetParent)
      },
      ...this.computePositionConfig
    })
      .then(({ x, y, placement, middlewareData }) => {
        Object.assign(this._tooltipContentEl.style, {
          left: `${x}px`,
          top: `${y}px`
        });
        // Accessing the data
        const { x: arrowX, y: arrowY } = middlewareData.arrow;
        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right'
        }[placement.split('-')[0]];
        Object.assign(this._tooltipArrowEl.style, {
          left: arrowX != null ? `${arrowX}px` : '',
          top: arrowY != null ? `${arrowY}px` : '',
          right: '',
          bottom: '',
          [staticSide]: '-4px'
        });
        this.dispatch({ eventName: 'tooltip-updated', detailObj: { x, y, placement, middlewareData } });
      })
      .catch((err) => {
        this.dispatch({ eventName: 'tooltip-update-failed', detailObj: { err } });
      });
  };

  /**
   * Handle appearance of tooltip
   */
  private _showTooltip = () => {
    this._tooltipContentEl.style.display = 'flex';
    this._updateTooltip();
  };

  /**
   * Handle tooltip hiding back
   */
  private _hideTooltip = () => {
    this._tooltipContentEl.style.display = 'none';
  };

  /**
   * Lifecycle method run after first update.
   * Attach event listeners with tooltip trigger element
   */
  protected async firstUpdated(_changedProperties: PropertyValues) {
    await this.updateComplete;
    [
      ['mouseenter', this._showTooltip],
      ['mouseleave', this._hideTooltip],
      ['focus', this._showTooltip],
      ['blur', this._hideTooltip]
    ].forEach(([event, listener]) => {
      this._tooltipTriggerEl.addEventListener(event as 'mouseenter' | 'mouseleave' | 'focus' | 'blur', listener as EventListener);
    });
    // Appending data in portal
    if (this.usePortal) {
      this.shadowRoot.getElementById('portal').appendChild(this._tooltipContentEl);
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-floating-tooltip', {});

    return html`
      <div class="${componentClassNames}">
        <div class="en-floating-tooltip-trigger" aria-describedby="${`floating-tooltip`}"><slot name="trigger"></slot></div>
        <div id="${`floating-tooltip`}" class="en-floating-tooltip-content" role="tooltip">
          <slot></slot>
          <div id="arrow" class="${classMap({ 'en-has-arrow': this.hasArrow })}"></div>
        </div>
        ${this.usePortal ? html`<div id="portal"></div>` : html``}
      </div>
    `;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENFloatingTooltip.el) === undefined) {
  customElements.define(ENFloatingTooltip.el, ENFloatingTooltip);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-floating-tooltip': ENFloatingTooltip;
  }
}
