import { TemplateResult, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENIconAipsLogo } from '../icon/icons/aips-logo';
import { ENIconEMark } from '../icon/icons/e-mark';
import { ENIconEcIqLogo } from '../icon/icons/ec-iq-logo';
import { ENIconEcLogo } from '../icon/icons/ec-logo';
import { ENIconElLogo } from '../icon/icons/el-logo';
import { ENIconEnPurple } from '../icon/icons/en-purple';
import { ENIconEp1Logo } from '../icon/icons/ep1-logo';
import { ENIconEp1MspLogo } from '../icon/icons/ep1-msp-logo';
import { ENIconEp1Networking } from '../icon/icons/ep1-networking';
import { ENIconExcorpBkLogo } from '../icon/icons/excorp-bk-logo';
import { ENIconExcorpWhLogo } from '../icon/icons/excorp-wh-logo';
import { ENIconMspLogo } from '../icon/icons/msp-logo';
import { ENIconSdwLogo } from '../icon/icons/sdw-logo';
import { ENIconUztnaLogo } from '../icon/icons/uztna-logo';
import { ENIconEp1Security } from '../icon/icons/ep1-security';
import styles from './product-logo.scss';

/**
 * Component: en-product-logo
 * @slot - The components content
 */
export class ENProductLogo extends ENElement {
  static readonly el = 'en-product-logo';

  private readonly elementMap = register({
    elements: [
      // Logo of ExtremeCloud (ec)
      [ENIconEcLogo.el, ENIconEcLogo],

      // Logo of ExtremeCloud MSP (msp)
      [ENIconMspLogo.el, ENIconMspLogo],

      // Logo of ExtremeCloud SD-WAN (sdw)
      [ENIconSdwLogo.el, ENIconSdwLogo],

      // Logo of ExtremeCloud IQ (ec-iq)
      [ENIconEcIqLogo.el, ENIconEcIqLogo],

      // Logo of ExtremeCloud Universal ZTNA (uztna)
      [ENIconUztnaLogo.el, ENIconUztnaLogo],

      // Logo of Extreme Platform (ep1)
      [ENIconEp1Logo.el, ENIconEp1Logo],

      // Logo of Extreme Platform ONE MSP(ep1-msp)
      [ENIconEp1MspLogo.el, ENIconEp1MspLogo],

      // Logo of Extreme Labs (el)
      [ENIconElLogo.el, ENIconElLogo],

      // Logo of Extreme Corps - Black (excorps-bk)
      [ENIconExcorpBkLogo.el, ENIconExcorpBkLogo],

      // Logo of Extreme Corps - White (excorps-wh)
      [ENIconExcorpWhLogo.el, ENIconExcorpWhLogo],

      // Logo of Extreme AI Platform Studio (aips)
      [ENIconAipsLogo.el, ENIconAipsLogo],

      // Logo of E-mark
      [ENIconEMark.el, ENIconEMark],

      // Logo of Extreme Corps - Purple
      [ENIconEnPurple.el, ENIconEMark],

      // Logo of EP1 Networking
      [ENIconEp1Networking.el, ENIconEp1Networking],

      // Logo of EP1 Security
      [ENIconEp1Security.el, ENIconEp1Security]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private productLogo?: any;

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Product variant
   * Display the product logo based on the input.Default is 'xc'.
   */
  @property()
  product?:
    | 'ec'
    | 'msp'
    | 'sdw'
    | 'ec-iq'
    | 'ep1'
    | 'ep1-msp'
    | 'uztna'
    | 'el'
    | 'aips'
    | 'excorp-bk'
    | 'excorp-wh'
    | 'ep1-networking'
    | 'e-mark'
    | 'ep1-security'
    | 'en-purple' = 'ec';

  /**
   * Width property
   * - If set, the logo will be constrained to this width in px
   */
  @property({ type: String })
  width?: string;

  /**
   * Height property
   * - If set, the logo will be constrained to this height in px
   */
  @property({ type: String })
  height?: string;

  /**
   * Returns logo based on the value of 'variant' and 'icon'.
   */
  getProductLogoByVariant() {
    switch (this.product) {
      case 'ec':
        return ENIconEcLogo.el;
      case 'msp':
        return ENIconMspLogo.el;
      case 'sdw':
        return ENIconSdwLogo.el;
      case 'ec-iq':
        return ENIconEcIqLogo.el;
      case 'el':
        return ENIconElLogo.el;
      case 'ep1':
        return ENIconEp1Logo.el;
      case 'ep1-msp':
        return ENIconEp1MspLogo.el;
      case 'uztna':
        return ENIconUztnaLogo.el;
      case 'aips':
        return ENIconAipsLogo.el;
      case 'excorp-bk':
        return ENIconExcorpBkLogo.el;
      case 'excorp-wh':
        return ENIconExcorpWhLogo.el;
      case 'e-mark':
        return ENIconEMark.el;
      case 'en-purple':
        return ENIconEnPurple.el;
      case 'ep1-networking':
        return ENIconEp1Networking.el;
      case 'ep1-security':
        return ENIconEp1Security.el;
      default:
        return ENIconEcLogo.el;
    }
  }

  /**
   * WillUpdate lifecycle
   * 1. Iterates over the changed properties of the component after an update.
   */
  protected willUpdate(): void {
    this.productLogo = unsafeStatic(this.elementMap.get(this.getProductLogoByVariant()));
    this.setWidthHeight();
  }

  /**
   * Set the width and height
   * 1. Add a custom property to adjust the width of the logo
   * 2. Add a custom property to adjust the height of the logo
   */
  setWidthHeight() {
    if (this.width) {
      this.style.setProperty('--en-product-logo-widthh', this.width.toString() + 'px');
    }
    if (this.height) {
      this.style.setProperty('--en-product-logo-height', this.height.toString() + 'px');
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-product-logo', {});

    return html`
      <div class="${componentClassNames}">
        <${this.productLogo}></${this.productLogo}>
        <slot></slot>
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENProductLogo.el) === undefined) {
  customElements.define(ENProductLogo.el, ENProductLogo);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-product-logo': ENProductLogo;
  }
}
