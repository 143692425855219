import { ENProductLogo } from "en-react/dist/src/components/ProductLogo"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { COMPANY_NAME } from "src/utils/constants"
import AuthorizationMessage, { AuthMessageProps } from "./AuthorizationMessage"
import { useZtnaLaunchPageStyles } from "./ZtnaLaunchPage.styles"

const ZtnaLaunchPage: React.FC<AuthMessageProps> = (props) => {
  const classes = useZtnaLaunchPageStyles()
  const currentTime = new Date()

  return (
    <div className={classes.container}>
      <div className={classes.backgroundImage} />
      <div className={classes.content}>
        <AuthorizationMessage {...props} />
        <div className={classes.bottomText}>
          <div className={classes.footer}>
            <ENProductLogo product={"ep1-security"} />
          </div>
          <ENTextPassage>
            <p className={classes.footerText}>
              © {currentTime.getFullYear()} {COMPANY_NAME} - All Rights Reserved
            </p>
          </ENTextPassage>
        </div>
      </div>
    </div>
  )
}

export default ZtnaLaunchPage
