import { TemplateResult, unsafeCSS } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENFieldNote } from '../field-note/field-note';
import styles from './textarea.scss';

/**
 * Component: en-textarea
 * - The textarea is often used in a form, to collect user inputs like comments or reviews.
 * @slot "before" - The content that appears before the text in the input
 * @slot "after" - The content that appears after the text in the input
 * @slot "field-note" - If content is slotted, it will display in place of the fieldNote property
 * @slot "error" - If content is slotted, it will display in place of the errorNote property
 */
export class ENTextarea extends ENElement {
  static el = 'en-textarea';

  private elementMap = register({
    elements: [[ENFieldNote.el, ENFieldNote]],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private fieldNoteEl = unsafeStatic(this.elementMap.get(ENFieldNote.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Rows attribute
   * - Specifies the visible number of lines in a text area
   */
  @property({ type: Number })
  rows?: number = 1;

  /**
   * Cols attribute
   * - Specifies the visible width of a text area
   */
  @property({ type: Number })
  cols?: number;

  /**
  * The auto text which has to displayed in the text field
  */
  @state()
  private suggestion?: string;

  /**
   * isActive
   * - Dynamically sets to true if the textarea has a value
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * Readonly attribute
   * - Specifies that an input field is read-only
   */
  @property({ type: Boolean })
  isReadonly?: boolean;

  /**
   * Error state
   * - Changes the component's treatment to represent an error state
   */
  @property({ type: Boolean })
  isError?: boolean;

  /**
   * Disabled attribute
   * - Changes the component's treatment to represent a disabled state
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Disabled resize attribute
   * - Changes the component's treatment to represent a disabled resize state
   */

  @property({ type: Boolean })
  isDisabledResize?: boolean;

  /**
   * resizeDirection
   * - **horizontal: ** Only allow resizing in horizontal direction.
   * - **vertical: ** Only allow resizing in vertical direction.
   * Default is both. Don't set it along with `isDisabledResize`.
   */
  @property()
  resizeDirection?: 'horizontal' | 'vertical' | 'both' = 'both';

  /**
   * Required attribute
   * - Specifies that an input field must be filled out before submitting the form
   */
  @property({ type: Boolean })
  isRequired?: boolean;

  /**
   * If true it will hide the required asterisk. Default is true.
   */
  @property({ type: Boolean })
  hideRequiredAsterisk?: boolean = true;

  /**
   * Optional state
   * - Specifies that a field is optional and adds the text 'optional' to the label
   */
  @property({ type: Boolean })
  isOptional?: boolean;

  /**
   * Autofocus attribute
   * - When present, it specifies that the text area should automatically get focus when the page loads
   */
  @property({ type: Boolean })
  isFocused?: boolean;

  /**
   * Hide label?
   * - If true, hides the label from displaying
   */
  @property({ type: Boolean })
  hideLabel?: boolean;

  /**
   * hideActiveLabelText
   * If true hide active label else show it. It is different from hideLabel. In hideLabel label is hidden, but in this case label will be hidden in active state only
   */
  @property({ type: Boolean })
  hideActiveLabelText?: boolean = false;

  /**
   * Label attribute
   * - Specifies what content to enter within the <input> element
   */
  @property()
  label: string = 'Label';

  /**
   * Placeholder attribute
   * - Specifies a short hint that describes the expected value of an <input> element
   */
  @property()
  placeholder?: string;

  /**
   * Name attribute
   * - Specifies the name of an <input> element
   */
  @property()
  name?: string;

  /**
   * Value attribute
   * - Specifies the value of an <input> element
   */
  @property()
  value?: string;

  /**
   *  Error message
   * - An error field note that displays below the input
   */
  @property()
  errorNote?: string;

  /**
   * Field note
   * - The helper text that displays below the input
   */
  @property()
  fieldNote?: string;

  /**
   * Id attribute
   * - The ID used for A11y and to associate the label with the input
   */
  @property()
  fieldId?: string;

  /**
   * aria-describedby attribute
   * - Applied to the field note or error note for A11y
   */
  @property()
  ariaDescribedBy?: string;

  /**
   * Min length
   * - Specifies the minimum number of characters required in an <input> element
   */
  @property({ type: Number })
  minLength?: number;

  /**
   * Max length
   * - Specifies the maximum number of characters required in an <input> element
   */
  @property({ type: Number })
  maxLength?: number;

  /**
   * Maxlength value
   * - Dynamically outputs the number of characters inside the input field
   */
  @property({ type: Number })
  maxLengthValue?: number;

  /**
   * enableSlotAfterClick value
   * - If true, then enable slot after click. By default it is undefined or false.
   */
  @property({ type: Boolean })
  enableSlotAfterClick?: boolean;

  /**
   * enableSlotAfterClick value
   * - If true, then enable slot before click. By default it is undefined or false.
   */
  @property({ type: Boolean })
  enableSlotBeforeClick?: boolean;

  /**
   * The set the strings which include all auto completion texts
   */
  @property({ type: Array })
  autoCompleteOptions: string[] = [];

  /**
   * Label Style variant
   * - **primary** renders the textarea to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the textarea to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   * - **tertiary** renders the textarea to be used on backgrounds with var(--en-theme-color-background-surface-elevation-2)
   */
  @property()
  variant?: 'primary' | 'secondary' | 'tertiary';

  /**
   * Connected callback
   * 1. Dynamically sets the fieldId and ariaDescribedBy for A11y
   */
  connectedCallback() {
    super.connectedCallback();
    /* 1 */
    this.fieldId = this.fieldId || nanoid();
    if (this.fieldNote) {
      this.ariaDescribedBy = this.ariaDescribedBy || nanoid();
    }
  }

  /**
   * Run when value change
   * 1. If there is a value, set isActive to true to move the label and get the length and set it to the maxlengthValue
   * 2. If isFocused is true, then autofocus the field on page load
   */
  activateInputElement() {
    const textArea = this.shadowRoot.querySelector<HTMLTextAreaElement>('.en-c-textarea__input');

    /* 1 */
    if (this.value && this.value.length > 0) {
      this.isActive = true;
      this.maxLengthValue = this.value.length;
    }
    /* 2 */
    if (this.isFocused) {
      textArea.focus();
    }
    /* 3 */
    if (this.suggestion) {
      this.makeSuggestionScroll(textArea);
    }
  }

  /**
   * First updated lifecycle
   * 1. The timeout allows for the slotted elements to load in the DOM
   * 2. Set the padding for the textarea after to element has loaded in the DOM
   */
  firstUpdated() {
    /* 1 */
    setTimeout(() => {
      /* 2 */
      this.setInputPadding();
    }, 0);
    if (this.value) {
      this.activateInputElement();
    }
  }

  /**
   * Updated lifecycle
   * 1. Iterates over the changed properties of the component after an update.
   * 2. Checks if the changed property is 'value' and it has been modified.
   * 3. If value exists then activating input element.
   * @param changedProperties - A map of changed properties in the component after an update.
   */
  updated(changedProperties: Map<string, unknown>) {
    /* 1 */
    changedProperties.forEach((oldValue, propName) => {
      /* 2 */
      if (propName === 'value' && this.value !== oldValue) {
        if (this.value) {
          /* 3 */
          this.activateInputElement();
        }
      }
    });
  }

  /**
   * Handle on change events
   * 1. Update the maxlengthValue as text is being inputted in the field
   * 2. If the value is greater than zero, then set active to true to move the label above the textarea
   * 3. If the value is less than zero, then set active to false and move the label back to its original position
   * 4. Dispatch the custom event
   */
  handleOnChange(e: Event) {
    /* 1 */
    const value = (e.target as HTMLInputElement).value;
    this.value = value;
    this.maxLengthValue = value.length;

    if (value.length > 0) {
      this.isActive = true; /* 2 */
    } else {
      this.isActive = false; /* 3 */
    }
    this.suggestion = this.getSuggestion(value);

    /* 4 */
    this.dispatch({
      eventName: 'change',
      detailObj: {
        value: this.value
      }
    });
  }

  /**
    * Handle keyboard events
    * 1. Update the text with the current suggested value in the field.
    */
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab' && this.suggestion) {
      event.preventDefault();
      /* 1 */
      this.value = this.suggestion;
      this.suggestion = '';
      this.updateComplete.then(() => this.moveCursorToTextEnd());
    }
  }

  /**
   * Move the cursor and scroll to the text end in the field.
   */
  moveCursorToTextEnd() {
    const textField = this.shadowRoot?.querySelector('.en-c-textarea__input') as HTMLInputElement;
    if (textField.scrollWidth > textField.clientWidth) {
      const length = textField.value.length;
      textField.setSelectionRange(length, length);
      textField.focus();
      textField.scrollLeft = textField.scrollWidth;
    }
  }

  /*
  Fetch and returns the suggestion that matches the current field value.
  */
  getSuggestion(value: string): string {
    if (!value) {
      return '';
    }
    let match = this.autoCompleteOptions.find((option) => option.toLowerCase().startsWith(value.toLowerCase()));
    return match || '';
  }

  /*
  If suggestion has value and its longer , then the suggestion text scrolls along with the actual text.
  */
  makeSuggestionScroll(textField: HTMLTextAreaElement) {
    if (textField.scrollHeight > textField.clientHeight) {
      const suggestionContainer = this.shadowRoot?.querySelector('.en-c-textarea__suggestion');
      textField.addEventListener('scroll', () => {
        suggestionContainer.scrollTop = textField.scrollTop;
      });
    }
  }

  /**
   * Set textarea padding
   * 1. Set the padding-left for the textarea field based on the before slotted content
   * 2. Set the padding-right for the textarea field based on the after slotted content
   */
  setInputPadding() {
    /* 1 */
    const beforeEl = this.shadowRoot.querySelector<HTMLElement>('.en-c-textarea__before');
    if (beforeEl) {
      let beforeWidth;
      if (this.isRequired && this.hideLabel) {
        beforeWidth = beforeEl.clientWidth + 16;
      } else {
        beforeWidth = beforeEl.clientWidth + 24;
      }
      this.style.setProperty('--en-textarea-padding-start', beforeWidth + 'px');
    }
    /* 2 */
    const afterEl = this.shadowRoot.querySelector<HTMLElement>('.en-c-textarea__after');
    if (afterEl) {
      const afterWidth = afterEl.clientWidth + 24;
      this.style.setProperty('--en-textarea-padding-end', afterWidth + 'px');
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-textarea', {
      'en-has-hidden-label': this.hideLabel,
      'en-is-disabled': this.isDisabled,
      'en-is-disabled-resize': this.isDisabledResize,
      'en-resize-direction--horizontal': this.resizeDirection === 'horizontal',
      'en-resize-direction--vertical': this.resizeDirection === 'vertical',
      'en-is-required': this.isRequired,
      'en-is-error': this.isError,
      'en-is-active': this.isActive,
      'en-hide-active-label-text': this.hideActiveLabelText
    });
    if (this.variant === undefined) {
      this.variant = 'primary';
    }
    const labelClassNames = this.componentClassNames('en-c-switch', {
      'en-c-textarea__label': this.variant === 'primary',
      'en-c-textarea__label--secondary': this.variant === 'secondary',
      'en-c-textarea__label--tertiary': this.variant === 'tertiary'
    });

    return html`
      <div class="${componentClassNames}">
        <div class="en-c-textarea__container">
        ${this.value && this.suggestion
        ? html`
                <textarea
                  class="en-c-textarea__suggestion"
                  rows=${ifDefined(this.rows)}
            cols=${ifDefined(this.cols)}
            maxlength="${ifDefined(this.maxLength)}"
            minlength=${ifDefined(this.minLength)}
                  .value="${this.value + this.suggestion.slice(this.value.length)}"
                ></textarea>
              `
        : ''}
          <textarea
            class="en-c-textarea__input"
            id="${this.fieldId}"
            name="${ifDefined(this.name)}"
            .value="${ifDefined(this.value)}"
            ?readonly="${this.isReadonly}"
            ?required="${this.isRequired}"
            ?disabled="${this.isDisabled}"
            aria-describedby="${ifDefined(this.ariaDescribedBy)}"
            placeholder="${ifDefined(this.placeholder)}"
            maxlength="${ifDefined(this.maxLength)}"
            minlength=${ifDefined(this.minLength)}
            @input=${(e: Event) => this.handleOnChange(e)}
            @keydown=${this.handleKeyDown}
            rows=${ifDefined(this.rows)}
            cols=${ifDefined(this.cols)}
            .autofocus=${this.isFocused}
          ></textarea>
          <label class=${labelClassNames} for="${this.fieldId}">
            ${this.isRequired ? html`<span class="${classMap({ 'en-c-textarea__asterisk': true, 'en-hide': this.hideRequiredAsterisk })}">*</span>` : html``}
            <span>${this.label}</span>
            ${this.isOptional ? html`<em class="en-c-textarea__optional">(Optional)</em>` : html``}
          </label>
          ${this.slotNotEmpty('before') || (this.isRequired && this.hideLabel)
        ? html`
                <div class=${this.enableSlotBeforeClick ? 'en-c-textarea__before enable-click' : 'en-c-textarea__before'}>
                  ${this.slotNotEmpty('before') ? html` <slot name="before"></slot> ` : html``}
                  ${this.isRequired && this.hideLabel
            ? html` <span class="en-c-textarea__asterisk en-c-textarea__asterisk--hidden-label">${this.hideRequiredAsterisk ? ' ' : '*'}</span> `
            : html``}
                </div>
              `
        : html``}
          ${this.slotNotEmpty('after')
        ? html`
                <div class=${this.enableSlotAfterClick ? 'en-c-textarea__after enable-click' : 'en-c-textarea__after'}>
                  <slot name="after"></slot>
                </div>
              `
        : html``}
        </div>
        ${this.maxLength || this.fieldNote || this.errorNote || this.slotNotEmpty('field-note') || this.slotNotEmpty('error')
        ? html`
              <div class="en-c-textarea__footer">
                <div class="en-c-textarea__field-notes">
                  ${this.fieldNote || this.slotNotEmpty('field-note')
            ? html`
                    <slot name="field-note">
                      <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} id=${ifDefined(this.ariaDescribedBy)}>
                        ${this.fieldNote}
                      </${this.fieldNoteEl}>
                    </slot>
                  `
            : html``}
                  ${(this.errorNote || this.slotNotEmpty('error')) && this.isError
            ? html`
                    <slot name="error">
                      <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${true}> ${this.errorNote}
                      </${this.fieldNoteEl}>
                    </slot>
                  `
            : html``}
                </div>
                ${this.maxLength
            ? html`
                  <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${this.isError}>
                    ${this.maxLengthValue > 0 ? this.maxLengthValue : 0}/${this.maxLength}
                  </${this.fieldNoteEl}>
                `
            : html``}
              </div>
            `
        : html``}
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENTextarea.el) === undefined) {
  customElements.define(ENTextarea.el, ENTextarea);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-textarea': ENTextarea;
  }
}
