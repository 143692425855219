import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { ENElement } from '../ENElement';
import styles from './dropdown-panel.scss';

/**
 * @slot - The component content
 */
export class ENDropdownPanel extends ENElement {
  static el = 'en-dropdown-panel';

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Adds a max height and scrolling of the panel
   */
  @property({ type: Boolean })
  hasScroll?: boolean;

  /**
   * Changes styling on panel for variant with header
   */
  @property({ type: Boolean })
  hasHeader?: boolean = false;

  /**
   * Changes styling on panel for variant with footer
   */
  @property({ type: Boolean })
  hasFooter?: boolean = false;

  /**
   * Changes tab order of dropdown body
   */
  @property({ type: Number })
  bodyTabIndex?: number;

  /**
   * Controls whether the panel is visible.
   */
  @property({ type: Boolean })
  isActive?: boolean = true;

  /**
   * If true, close on clicking outside. If false, then panel not close on clicking outside. Default is true.
   */
  @property({ type: Boolean })
  closeOnClickOutside?: boolean = false;

  /**
   * Handle trigger click to toggle panel visibility
   */
  handleTriggerClick() {
    this.isActive = !this.isActive;
    this.dispatch({
      eventName: this.isActive ? 'open' : 'close',
      detailObj: {
        active: this.isActive
      }
    });
  }

  /**
   * Handles the click event outside the component:
   * 1. Check if closeOnClickOutside is enabled
   * 2. Check if the panel is active
   * 3. Determine if the click occurred inside the dropdown panel
   */
  handleOnClickOutside(event: MouseEvent) {
    /* 1 */
    if (!this.closeOnClickOutside) {
      return false;
    }
    /* 2 */
    if (this.isActive) {
      const didClickInside = event.composedPath().includes(this.shadowRoot.host);
      /* 3 */
      if (!didClickInside) {
        this.isActive = false;

        this.dispatch({
          eventName: 'close',
          detailObj: {
            active: this.isActive,
            byOutsideClick: true
          }
        });
      }
    }
  }

  /**
   * Handle keydown event
   * 1. When the Enter key is pressed on the trigger, open the list and prevent default button click
   * 2. When the Escape key is pressed, close the menu
   */
  handleOnKeydown(e: KeyboardEvent) {
    e.preventDefault();
    const { target } = e as any;
    /* 1 */
    if (this.slotNotEmpty('trigger') && target.matches('[slot="trigger"]') && e.key === 'Enter') {
      this.isActive = true;
      this.dispatch({
        eventName: 'open',
        detailObj: {
          active: this.isActive
        }
      });
    }
    /* 2 */
    if (this.slotNotEmpty('trigger') && e.key === 'Escape') {
      this.isActive = false;
      this.dispatch({
        eventName: 'close',
        detailObj: {
          active: this.isActive
        }
      });
    }
  }

  /**
   * Initialize functions
   */
  constructor() {
    super();
    this.handleTriggerClick = this.handleTriggerClick.bind(this);
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
  }

  /**
   * Connected callback lifecycle
   * 1. Add mousedown event listener
   */
  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('mousedown', this.handleOnClickOutside, false);
  }

  /**
   * Disconnected callback lifecycle
   * 1. Remove mousedown event listener
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('mousedown', this.handleOnClickOutside, false);
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-dropdown-panel', {
      'en-has-header': this.hasHeader === true,
      'en-has-footer': this.hasFooter === true,
      'en-has-scroll': this.hasScroll === true
    });

    return html`
      ${this.slotNotEmpty('trigger') &&
      html`
        <div class="en-c-dropdown-panel__trigger" @click=${this.handleTriggerClick} @keydown=${this.handleOnKeydown}>
          <slot name="trigger"></slot>
        </div>
      `}
      ${this.isActive
        ? html`
            <div class="${componentClassNames}">
              ${this.slotNotEmpty('header') &&
              html`
                <div class="en-c-dropdown-panel__header">
                  <slot name="header"></slot>
                </div>
              `}
              <div class="en-c-dropdown-panel__body" tabindex=${ifDefined(this.bodyTabIndex ? `${this.bodyTabIndex}` : undefined)}>
                <slot></slot>
              </div>
              ${this.slotNotEmpty('footer') &&
              html`
                <div class="en-c-dropdown-panel__footer">
                  <slot name="footer"></slot>
                </div>
              `}
            </div>
          `
        : html``}
    `;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENDropdownPanel.el) === undefined) {
  customElements.define(ENDropdownPanel.el, ENDropdownPanel);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-dropdown-panel': ENDropdownPanel;
  }
}
