import { TemplateResult, unsafeCSS } from 'lit';
import { repeat } from 'lit-html/directives/repeat.js';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENChipGroup } from '../chip-group/chip-group';
import { ENChip } from '../chip/chip';
import { ENFieldNote } from '../field-note/field-note';
import styles from './text-field.scss';

/**
 * Component: en-text-field
 * - A text field allows users to input alphanumeric data, such as names, addresses, or messages.
 * @slot "before" - The content that appears before the text in the input
 * @slot "after" - The content that appears after the text in the input
 * @slot "field-note" - If content is slotted, it will display in place of the fieldNote property
 * @slot "error" - If content is slotted, it will display in place of the errorNote property
 */
export class ENTextField extends ENElement {
  static el = 'en-text-field';

  private elementMap = register({
    elements: [
      [ENFieldNote.el, ENFieldNote],
      [ENChipGroup.el, ENChipGroup],
      [ENChip.el, ENChip]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private fieldNoteEl = unsafeStatic(this.elementMap.get(ENFieldNote.el));
  private chipEl = unsafeStatic(this.elementMap.get(ENChip.el));
  private chipGroupEl = unsafeStatic(this.elementMap.get(ENChipGroup.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * If true will keep old tooltip, else replace with new tooltip
   * Default is true
   */
  @property({ type: Boolean })
  keepOldTooltip?: boolean = true;

  /**
   * Type variants
   * - Specifies the type <input> element to display
   * - **text** renders a standar text input
   * - **email** renders a text input specifically for an email format
   * - **number** renders an input for number values only
   * - **password** renders an input for a password input format
   * - **url** renders an input for urls only
   * - **tel** renders an input for telephone number values only
   */
  @property()
  type: 'text' | 'email' | 'number' | 'password' | 'url' | 'hidden' | 'tel' = 'text';

  /**
   * If set to true, then apply overflow ellipses in case text exceed input field. Default is false.
   */
  @property({ type: Boolean })
  applyOverflowEllipses: boolean = false;

  /**
   * If set to true, align chips to right. Default is false.
   */
  @property({ type: Boolean })
  alignChipsToRight?: boolean = false;

  /**
   * If set to true, then show only selection count chip. Default is false.
   */
  @property({ type: Boolean })
  showOnlySelectionCountChip?: boolean = false;

  /**
   * isActive
   * - Dynamically sets to true if the input has a value
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * Readonly attribute
   * - Specifies that an input field is read-only
   */
  @property({ type: Boolean })
  isReadonly?: boolean;

  /**
   * Error state
   * - Changes the component's treatment to represent an error state
   */
  @property({ type: Boolean })
  isError?: boolean;

  /**
   * Disabled attribute
   * - Changes the component's treatment to represent a disabled state
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Required attribute
   * - Specifies that an input field must be filled out before submitting the form
   */
  @property({ type: Boolean })
  isRequired?: boolean;

  /**
   * Optional state
   * - Specifies that a field is optional and adds the text 'optional' to the label
   */
  @property({ type: Boolean })
  isOptional?: boolean;

  /**
   * Autofocus attribute
   * - When present, it specifies that the text area should automatically get focus when the page loads
   */
  @property({ type: Boolean })
  isFocused?: boolean;

  /**
   * Hide label?
   * - If true, hides the label from displaying
   */
  @property({ type: Boolean })
  hideLabel?: boolean;

  /**
   * Label attribute
   * - Specifies what content to enter within the <input> element
   */
  @property()
  label: string = 'Label';

  /**
   * Placeholder attribute
   * - Specifies a short hint that describes the expected value of an <input> element
   */
  @property()
  placeholder?: string;

  /**
   * Name attribute
   * - Specifies the name of an <input> element
   */
  @property()
  name?: string;

  /**
   * Value attribute
   * - Specifies the value of an <input> element
   */
  @property()
  value?: string;

  /**
   * isChipDismissible attribute
   * - If set to true, then enable dismissing on chips. Default is false.
   */
  @property({ type: Boolean })
  isChipDismissible?: boolean = false;

  /**
   * Values attribute
   * If this will be set text field can take multiple values comma or ;'; seperated string, and those values will be shown in form of chips. This field and value cannot work simulataneously. If this field will be set value field will be set empty. Use $$## if there is conflict with comma.
   */
  @property()
  values: string;

  /**
   * Chip data-info attribute. It should be set in same order of values. Use $$## if there is conflict with comma
   */
  @property()
  chipInfos: string = '';

  /**
   * By default chip show tooltip when text overflow. But if you want to show tooltip always on chip, in that case
   * you can use this property. Pass tooltip text that has to be shown always in it. Order of text items must be same as `values` property
   * Use $$## if there is conflict with comma
   */
  @property()
  chiptooltipTexts?: string = '';

  /**
   * By default chip show tooltip when text overflow. But if you want to show tooltip always on chip, in that case
   * you can use this property. This property is relevant to set only when tooltipText will be set as these both properties work together
   * Order of text items must be same as `values` property
   * Use $$## if there is conflict with comma
   */
  @property()
  chipTexts?: string = '';

  /**
   * Set child chip max width except count chip. Use only one, either it or `setFlexOnChips`. It will override by `setFlexOnChips`. This property or setFlexOnChips is required to set in order to control text overflow.
   */
  @property({ type: String })
  chipsMaxWidth?: string = '86px';

  /**
   * Set child chip min width except count chip. This property is required to set in order to automatically determine chips visible.
   */
  @property({ type: String })
  chipsMinWidth?: string = '86px';

  /**
   * chipVariant
   * - **default** A chip with a high contrast background
   * - **secondary** A chip with a medium contrast background
   * - **tertiary** A chip with a low contrast background
   * - **green** A chip with a green background
   * - **red** A chip with a red background
   * - **blue** A chip with a blue background
   * - **amber** A chip with a amber background
   * - **purple** A chip with a purple background
   */
  @property()
  chipVariant?: 'default' | 'secondary' | 'tertiary' | 'green' | 'red' | 'blue' | 'amber' | 'purple' | 'neutral' = 'default';

  /**
   *  Error message
   * - An error field note that displays below the input
   */
  @property()
  errorNote?: string;

  /**
   * Field note
   * - The helper text that displays below the input
   */
  @property()
  fieldNote?: string;

  /**
   * Id attribute
   * - The ID used for A11y and to associate the label with the input
   */
  @property()
  fieldId?: string;

  /**
   * If true show pointer on input. Default is false.
   */
  @property({ type: Boolean })
  showPointerOnInput?: boolean = false;

  /**
   * aria-describedby attribute
   * - Applied to the field note or error note for A11y
   */
  @property()
  ariaDescribedBy?: string;

  /**
   * Min length
   * - Specifies the minimum number of characters required in an <input> element
   */
  @property({ type: Number })
  minLength?: number;

  /**
   * Max length
   * - Specifies the maximum number of characters required in an <input> element
   */
  @property({ type: Number })
  maxLength?: number;

  /**
   * If true it will hide the required asterisk. Default is true.
   */
  @property({ type: Boolean })
  hideRequiredAsterisk?: boolean = true;

  /**
   * Maxlength value
   * - Dynamically outputs the number of characters inside the input field
   */
  @property({ type: Number })
  maxLengthValue?: number;

  /**
   * Minimum value
   * - Specifies a minimum value for an <input> element
   */
  @property({ type: Number })
  min?: number;

  /**
   * Maximum value
   * - Specifies a maximum value for an <input> element
   */
  @property({ type: Number })
  max?: number;

  /**
   * If set to true, then show text-field without border. Default is false.
   */
  noBorder?: boolean = false;

  /**
   * Autocomplete property
   * - Specifies whether an <input> element should have autocomplete enabled
   */
  @property()
  autoComplete?: 'on' | 'off';

  /**
   * Indicates whether the text field resizing is enabled.
   * When set to `true`, the text field can be resized by the user.
   * Defaults to `false`.
   */
  @property({ type: Boolean })
  enableTextFieldResizing?: boolean = false;

  /**
   * textSizerOffset property
   * - Default is 16. This should be equal to max(left padding, right padding) + left/right border on text-field input.
   */
  @property({ type: Number })
  textSizerOffset?: number = 18;

  /**
   * paddingEndOffset property
   * - Default is 0. To increase end input padding.
   */
  @property({ type: Number })
  paddingEndOffset?: number = 0;

  /*
   * enableSlotAfterClick value
   * - If true, then enable slot after click. By default it is undefined or false.
   */
  @property({ type: Boolean })
  enableSlotAfterClick?: boolean;

  /**
   * enableSlotAfterClick value
   * - If true, then enable slot before click. By default it is undefined or false.
   */
  @property({ type: Boolean })
  enableSlotBeforeClick?: boolean;

  /**
   * forceWidthBeforeSlot value
   * - In some cases component not able to load slotted elements before rendering.
   * At that time predefined width can be assigned to unloaded slotted elements.
   * This property control width of 'before' slotted element.
   * This width will only apply if 'before' slotted element will not be able to load before rendering.
   */
  @property({ type: Number })
  forceWidthBeforeSlot?: number = 0;

  /**
   * The set the strings which include all auto completion texts
   */
  @property({ type: Array })
  autoCompleteOptions: string[] = [];
  /**
   * Variant
   * - **primary** renders the text-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the text-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   * - **tertiary** renders the text-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-2)
   */
  @property()
  variant?: 'primary' | 'secondary' | 'tertiary';

  /**
   * If true, show dropdown on clicking counter chip. Default is false.
   */
  @property({ type: Boolean })
  showCounterChipDropdownMenu?: boolean = false;

  /**
   * Set it in order to change chip dropdown menu max height. Expected valid height string css value.
   */
  @property()
  chipDropdownMenuMaxHeight?: string = '205px';

  /**
   * If true, show tooltip on menu chip. This property will be used with chip-group
   * Default is true.
   */
  @property({ type: Boolean })
  showTooltipOnMenuChip?: boolean = true;

  /**
   * Expects string value of trigger menu panel width. It is for trigger menu for overflow chip.
   * Default value is max-content
   */
  @property()
  triggerMenuPanelWidth?: string = 'max-content';

  @query('.en-c-text-field__chip-panel')
  chipPanel: HTMLDivElement;

  @state()
  inputWidth = '0px';

  /**
   * The auto text which has to displayed in the text field
   */
  @state()
  suggestion?: string;

  /**
   * hideActiveLabelText
   * If true hide active label else show it. It is different from hideLabel. In hideLabel label is hidden, but in this case label will be hidden in active state only
   */
  @property({ type: Boolean })
  hideActiveLabelText?: boolean = false;

  /**
   * If set to false, the after slot will not be rendered at all
   * This helps prevent issues with conditional slot rendering from outside
   */
  @property({ type: Boolean })
  renderAfterSlot: boolean = true;

  /**
   * If true, hides the after slot visually while maintaining its space
   */
  @property({ type: Boolean })
  hideAfterSlot: boolean = false;

  /**
   * Connected callback
   * 1. Dynamically sets the fieldId and ariaDescribedBy for A11y
   */
  connectedCallback() {
    super.connectedCallback();
    /* 1 */
    this.fieldId = this.fieldId || nanoid();
    if (this.fieldNote) {
      this.ariaDescribedBy = this.ariaDescribedBy || nanoid();
    }
  }

  /**
   * Run when value change
   * 1. If there is a value, set isActive to true to move the label and get the length and set it to the maxlengthValue
   * 2. If isFocused is true, then autofocus the field on page load
   * 3. If suggetion has value , then the suggestion text scrolls along with the actual text.
   */

  activateInputElement() {
    const textField = this.shadowRoot.querySelector<HTMLTextAreaElement>('.en-c-text-field__input');

    /* 1 */
    if (this.value && this.value != null) {
      this.isActive = true;
      this.maxLengthValue = typeof this.value === 'number' ? String(this.value).length : this.value.length;
    }

    /* 2 */
    if (this.isFocused) {
      textField.focus();
    }

    /* 3 */
    if (this.suggestion) {
      this.makeSuggestionScroll(textField);
    }

    if (this.values) {
      this.isActive = true;
    } else if (!this.values && !this.value) {
      this.isActive = false;
    }
  }

  /**
   * First updated lifecycle
   * 1. The timeout allows for the slotted elements to load in the DOM
   * 2. Set the padding for the input after to element has loaded in the DOM
   */
  firstUpdated() {
    /* 1 */
    if (this.values) {
      this.value = '';
    }
    /* 2 */
    setTimeout(() => {
      this.setInputPadding();
    }, 0);

    if (this.value || this.values) {
      this.activateInputElement();
    }
  }

  /**
   * Updated lifecycle
   * 1. Iterates over the changed properties of the component after an update.
   * 2. Checks if the changed property is 'value' and it has been modified.
   * 3. If value exists then activating input element.
   * 4. Check if paddingEndOffset changed, then update input padding
   * @param changedProperties - A map of changed properties in the component after an update.
   */
  updated(changedProperties: Map<string, unknown>) {
    /* 1 */
    const textSizer = this.enableTextFieldResizing ? (this.shadowRoot!.getElementById('text-sizer') as HTMLSpanElement) : null;
    changedProperties.forEach((oldValue, propName) => {
      /* 2 */
      if (propName === 'values' && this.values !== oldValue) {
        if (!!this.values) {
          this.value = '';
          this.setInputPadding();
          if (this.enableTextFieldResizing) {
            textSizer.innerText = this.values;
            this._updateTextSizerWidth();
          }
        }
        this.activateInputElement();
      }
      if (propName === 'value' && this.value !== oldValue) {
        if (this.value) {
          /* 3 */
          this.activateInputElement();
          if (this.enableTextFieldResizing) {
            textSizer.innerText = this.value;
            this._updateTextSizerWidth();
          }
        } else if (!this.values) {
          this.isActive = false;
          if (this.enableTextFieldResizing) {
            textSizer.innerText = '';
            this._updateTextSizerWidth();
          }
        }
      } else if (propName === 'paddingEndOffset' && this.paddingEndOffset !== oldValue) {
        /* 4 */
        this.setInputPadding();
      } else if (propName === 'renderAfterSlot' && this.renderAfterSlot !== oldValue) {
        this.setInputPadding();
      }
    });
  }

  /**
   * @param el Element
   * @returns string consist of element font-weight, font-size and font-family
   */
  private getCanvasFont(el: Element) {
    if (el) {
      const { fontWeight, fontSize, fontFamily } = getComputedStyle(el);
      return `${fontWeight} ${fontSize} ${fontFamily}`;
    }
    return ``;
  }

  /**
   *
   * @param text String whose canvas length has to be measured
   * @param font Font weight, size and family of element which renders that string so that exact length can be measured
   * @returns Canvas render length of string
   */
  private getTextWidth(text: string, font: string) {
    // re-use canvas object for better performance
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  /**
   * Updates the width of the text field based on the width of the text content.
   *
   * This method calculates the width of the text content within the text-sizer element
   * and sets the width of the text field accordingly. It uses a canvas font to measure
   * the text width accurately.
   *
   * @private
   */
  private _updateTextSizerWidth() {
    const textSizer = this.shadowRoot?.getElementById('text-sizer') as HTMLSpanElement;
    if (textSizer) {
      const textSizerFont = this.getCanvasFont(textSizer);
      const textSizerWidth = this.getTextWidth(textSizer.textContent, textSizerFont) + 2 * this.textSizerOffset;
      const textField = this.shadowRoot?.querySelector('.en-c-text-field') as HTMLElement;
      textField.style.width = `${textSizerWidth}px`;
    }
  }

  /**
   * Handle on change events
   * 1. Update the maxlengthValue as text is being inputted in the field
   * 2. If the value is greater than zero, then set active to true to move the label above the input
   * 3. If the value is less than zero, then set active to false and move the label back to its original position
   * 4. Dispatch the custom event
   */
  handleOnChange(e: Event) {
    /* 1 */
    const value = (e.target as HTMLInputElement).value;
    if (this.type === 'number' && e instanceof InputEvent && e.data === "-" && !value) {
      /** To prevent issues caused by the restriction of special characters in type:number, assigning only '-' is problematic. 
       * To resolve this, skip updating this.value if e.data is '-'. 
       * This ensures that this.value correctly represents a negative number when '-' follows a numeric value (e.g., -3). **/
      return;
    }
    else {
      this.value = value;
      this.maxLengthValue = value.length;
    }

    if (this.maxLengthValue > 0) {
      this.isActive = true; /* 2 */
    } else {
      this.isActive = false; /* 3 */
    }

    this.suggestion = this.getSuggestion(this.value);

    if (this.enableTextFieldResizing) {
      const textSizer = this.shadowRoot!.getElementById('text-sizer') as HTMLSpanElement;
      textSizer.innerText = this.value;
      this._updateTextSizerWidth();
    }
    /* 4 */
    this.dispatch({
      eventName: 'change',
      detailObj: {
        value: this.value
      }
    });
  }

  /**
   * Handle keyboard events
   * 1. Update the text with the current suggested value in the field.
   */
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab' && this.suggestion) {
      event.preventDefault();
      /* 1 */
      this.value = this.suggestion;
      this.suggestion = '';
      this.updateComplete.then(() => this.moveCursorToTextEnd());
    } else if (event.key === 'Enter') {
      this.dispatch({
        eventName: 'enter',
        detailObj: {
          value: this.value
        }
      });
    }
  }

  /**
   * Move the cursor and scroll to the text end in the field.
   */
  moveCursorToTextEnd() {
    const textField = this.shadowRoot!.querySelector('.en-c-text-field__input') as HTMLInputElement;
    if (textField.scrollWidth > textField.clientWidth) {
      const length = textField.value.length;
      textField.setSelectionRange(length, length);
      textField.focus();
      textField.scrollLeft = textField.scrollWidth;
    }
  }

  /*
   If suggestion has value and its longer , then the suggestion text scrolls along with the actual text.
  */
  makeSuggestionScroll(textField: HTMLTextAreaElement) {
    if (textField.scrollWidth > textField.clientWidth) {
      const suggestionContainer = this.shadowRoot!.querySelector('.en-c-text-field__suggestion');
      textField.addEventListener('scroll', () => {
        suggestionContainer.scrollLeft = textField.scrollLeft;
      });
    }
  }

  /*
   Fetch and returns the suggestion that matches the current field value.
  */
  getSuggestion(value: string): string {
    if (!value) {
      return '';
    }
    let match = this.autoCompleteOptions.find((option) => option.toLowerCase().startsWith(value.toLowerCase()));
    return match || '';
  }

  /**
   * Set input padding
   * 1. Set the padding-left for the input field based on the before slotted content
   * 2. Set the padding-right for the input field if it's rendered
   * 3. Reset padding when after slot is removed
   */
  setInputPadding = () => {
    if (this.chipPanel) {
      this.chipPanel.style.setProperty('--en-text-field-dev-visibility', 'visible');
    }
    /* 1 */
    const beforeEl = this.shadowRoot.querySelector<HTMLElement>('.en-c-text-field__before');
    let beforeWidth = 0;
    if (beforeEl) {
      if (this.isRequired && this.hideLabel) {
        beforeWidth = (beforeEl.clientWidth || parseInt(`${this.forceWidthBeforeSlot}`, 10) || 0) + 16;
      } else {
        beforeWidth = (beforeEl.clientWidth || parseInt(`${this.forceWidthBeforeSlot}`, 10) || 0) + 24;
      }

      this.style.setProperty('--en-text-field-padding-start', beforeWidth + 'px');
      if (this.chipPanel) {
        this.chipPanel.style.setProperty('--en-text-field-dev-left', beforeWidth + 'px');
      }
    }
    /* 2 */
    const afterEl = this.shadowRoot.querySelector<HTMLElement>('.en-c-text-field__after');
    let afterWidth = 0;
    if (this.renderAfterSlot) {
      if (afterEl) {
        afterWidth = afterEl.clientWidth + 24 + parseInt(`${this.paddingEndOffset}`, 10) || 0;
        this.style.setProperty('--en-text-field-padding-end', afterWidth + 'px');
        if (this.chipPanel) {
          this.chipPanel.style.setProperty('--en-text-field-dev-right', afterWidth + 'px');
        }
      }
    } else {
      /* 3 */
      this.style.removeProperty('--en-text-field-padding-end');
      if (this.chipPanel) {
        this.chipPanel.style.removeProperty('--en-text-field-dev-right');
      }
    }

    const textInput: HTMLInputElement = this.shadowRoot.querySelector('.en-c-text-field__input');
    if (textInput) {
      let textInputWidth = textInput.clientWidth;
      textInputWidth -= beforeWidth || 8;
      textInputWidth -= afterWidth || 8;
      this.inputWidth = `${textInputWidth}px`;
    }
  };

  /**
   * Handle click on counter chip
   */
  handleClickOnCounterChips() {
    this.dispatch({ eventName: 'counterChipClicked' });
  }

  handleChipDismiss(evt: CustomEvent) {
    const chipContent = evt.detail.chipContent || '';
    const dataInfo = evt.detail.dataInfo || '';
    const isTextOverflowCase = evt.detail.isTextOverflowCase;

    const formattedChipContent = isTextOverflowCase ? chipContent.split(' ').slice(1).join(' ') : chipContent;
    let splitValues = [];
    if (this.values.includes('$$##')) {
      splitValues = this.values.split('$$##');
    }
    else {
      splitValues = this.values.split(',');
    }
    
    const findChipContentInValuesIndex = splitValues.findIndex((value) => value === formattedChipContent);
    if (findChipContentInValuesIndex > -1) {
      splitValues.splice(findChipContentInValuesIndex, 1);
      this.values = splitValues.join(',');
      if (this.values?.trim() === '') {
        this.isActive = false;
      }
    }

    this.dispatch({ eventName: 'chipDismiss', detailObj: { chipContent: formattedChipContent, dataInfo } });
  }

  /**
   * Focus on text field
   */
  public focusOnTextField = () => {
    const textField = this.shadowRoot!.querySelector('.en-c-text-field__input') as HTMLInputElement;
    textField.focus();
  };

  render() {
    const componentClassNames = this.componentClassNames('en-c-text-field', {
      'en-has-hidden-label': this.hideLabel,
      'en-is-disabled': this.isDisabled,
      'en-is-required': this.isRequired,
      'en-is-error': this.isError,
      'en-is-active': this.isActive,
      'en-hide-active-label-text': this.hideActiveLabelText,
      'en-is-multiple-values': !!this.values,
      'en-show-pointer-input': this.showPointerOnInput
    });
    if (this.variant === undefined) {
      this.variant = 'primary';
    }
    const labelClassNamesTextField = this.componentClassNames('en-c-switch', {
      'en-c-text-field__label': this.variant === 'primary',
      'en-c-text-field__label--secondary': this.variant === 'secondary',
      'en-c-text-field__label--tertiary': this.variant === 'tertiary'
    });

    const showChipPanel = !!this.values;
    const chipValues = showChipPanel ? this.values.includes('$$##')? this.values.split('$$##'): this.values.split(',') : [];
    const chipInfos = showChipPanel && this.chipInfos !== '' ? this.chipInfos.includes('$$##') ? this.chipInfos.split('$$##'):this.chipInfos.split(',') : [];
    const chipTexts = showChipPanel && this.chipTexts !== '' ? this.chipTexts.includes('$$##') ? this.chipTexts.split('$$##'): this.chipTexts.split(',') : [];
    const chiptooltipTexts = showChipPanel && this.chiptooltipTexts !== '' ? this.chiptooltipTexts.includes('$$##')? this.chiptooltipTexts.split('$$##'): this.chiptooltipTexts.split(',') : [];
    
    return html`
      <div class="${componentClassNames}">
        <div class="en-c-text-field__container">
          ${this.value && this.suggestion
        ? html`
                <input class="en-c-text-field__suggestion" type="${this.type}" .value="${this.value + this.suggestion.slice(this.value.length)}" />
              `
        : ''}
          <input
            class=${classMap({
          'en-c-text-field__input': true,
          'apply-overflow-ellipses': this.applyOverflowEllipses === true,
          'en-no-border': this.noBorder
        })}
            type="${this.type}"
            id="${this.fieldId}"
            name="${ifDefined(this.name)}"
            .value="${ifDefined(!this.value && !this.values ? '' : this.value)}"
            ?readonly="${this.isReadonly || this.values}"
            ?required="${this.isRequired}"
            ?disabled="${this.isDisabled}"
            autocomplete="${this.type === 'password' ? 'off' : this.autoComplete}"
            aria-describedby="${ifDefined(this.ariaDescribedBy)}"
            placeholder="${ifDefined(this.placeholder)}"
            maxlength="${ifDefined(this.maxLength)}"
            minlength=${ifDefined(this.minLength)}
            min=${ifDefined(this.min)}
            max=${ifDefined(this.max)}
            @input=${(e: Event) => this.handleOnChange(e)}
            @keydown=${this.handleKeyDown}
            .autofocus=${this.isFocused}
          />

          <label ?hidden="${this.type === 'hidden'}" class=${labelClassNamesTextField} for="${this.fieldId}">
            ${this.isRequired
        ? html`<span class="${classMap({ 'en-c-text-field__asterisk': true, 'en-hide': this.hideRequiredAsterisk })}">*</span>`
        : html``}
            <span>${this.label}</span>
            ${this.isOptional ? html`<em class="en-c-text-field__optional">(Optional)</em>` : html``}
          </label>
          ${this.enableTextFieldResizing ? html`<span id="text-sizer" class="en-c-text-field__text-sizer"></span>` : html``}
          ${this.slotNotEmpty('before') || (this.isRequired && this.hideLabel)
        ? html`
                <div class=${this.enableSlotBeforeClick && !this.isDisabled ? 'en-c-text-field__before enable-click' : 'en-c-text-field__before'}>
                  ${this.slotNotEmpty('before') ? html` <slot name="before"></slot> ` : html``}
                  ${this.isRequired && this.hideLabel
            ? html`
                        <span
                          class="${classMap({
              'en-c-text-field__asterisk': true,
              'en-c-text-field__asterisk--hidden-label': true
            })}"
                          >${this.hideRequiredAsterisk ? ' ' : '*'}</span
                        >
                      `
            : html``}
                </div>
              `
        : html``}
          ${this.renderAfterSlot && this.slotNotEmpty('after')
        ? html`
                <div
                  class=${classMap({
          'en-c-text-field__after': true,
          'enable-click': this.enableSlotAfterClick,
          'en-c-hide-after': this.hideAfterSlot
        })}
                >
                  <slot name="after"></slot>
                </div>
              `
        : html``}
          ${showChipPanel
        ? html`<div class="en-c-text-field__chip-panel">
                <${this.chipGroupEl} .keepOldTooltip=${this.keepOldTooltip} .showTooltipOnMenuChip=${this.showTooltipOnMenuChip} .alignChipsToRight=${this.alignChipsToRight} ?isDisabled=${this.isDisabled} @close=${this.handleChipDismiss} chipDropdownMenuMaxHeight=${this.chipDropdownMenuMaxHeight} style="--en-chip-group-dev-width:${this.inputWidth}" .textOverflow=${true} chipsMinWidth="${this.chipsMinWidth
          }" chipsMaxWidth="${this.chipsMaxWidth
          }" .showCounterChipDropdownMenu=${this.showCounterChipDropdownMenu} .dismissCounterAndExpandChips=${false} .addSomeDelayInTextOverflowHandling=${true} .autoDetermineChipsVisible=${true} .disableChipWrapping=${true} @showChips=${this.handleClickOnCounterChips
          }>
                    ${repeat(
            chipValues,
            (value, index) => `${value}${index}`,
            (value, index) =>
              html`<${this.chipEl} .keepOldTooltip=${this.keepOldTooltip} chipText="${chipTexts?.[index] || ''}" tooltipText="${chiptooltipTexts?.[index] || ''}" dataInfo="${chipInfos?.[index] || ''}" ?isDisabled=${this.isDisabled} @close=${this.handleChipDismiss} variant="${this.chipVariant}" .isDismissible=${this.isChipDismissible || false} data-testid="chip${index}">${value}</${this.chipEl}`
          )}

                </${this.chipGroupEl}>
          </div>`
        : html``}
        </div>
        ${this.maxLength || this.fieldNote || this.errorNote || this.slotNotEmpty('field-note') || this.slotNotEmpty('error')
        ? html`
              <div class="en-c-text-field__footer">
                <div class="en-c-text-field__field-notes">
                  ${this.fieldNote || this.slotNotEmpty('field-note')
            ? html`
                        <slot name="field-note">
                          <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} id=${ifDefined(this.ariaDescribedBy)}>
                            ${this.fieldNote}
                          </${this.fieldNoteEl}>
                        </slot>
                      `
            : html``}
                  ${(this.errorNote || this.slotNotEmpty('error')) && this.isError
            ? html`
                        <slot name="error">
                          <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${true}> ${this.errorNote} </${this.fieldNoteEl}>
                        </slot>
                      `
            : html``}
                </div>
                ${this.maxLength
            ? html`
                      <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${this.isError}>
                        ${this.maxLengthValue > 0 ? this.maxLengthValue : 0}/${this.maxLength}
                      </${this.fieldNoteEl}>
                    `
            : html``}
              </div>
            `
        : html``}
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENTextField.el) === undefined) {
  customElements.define(ENTextField.el, ENTextField);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-text-field': ENTextField;
  }
}

