import { TemplateResult, unsafeCSS } from 'lit';
import { property, queryAssignedElements } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { FormController } from '../../controllers/form';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENDivider } from '../divider/divider';
import { ENElement } from '../ENElement';
import styles from './button.scss';

/**
 * Component: en-button
 * - Buttons serve as interactive elements that enable users to execute actions or transition to different sections.
 * @slot "before" - Content to display before the Button text, typically an Icon
 * @slot "after" - Content to display after the Button text, typically an Icon
 */
export class ENButton extends ENElement {
  static el = 'en-button';

  private elementMap = register({
    elements: [[ENDivider.el, ENDivider]],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private dividerEl = unsafeStatic(this.elementMap.get(ENDivider.el));

  static get styles() {
    return unsafeCSS(styles);
  }

  protected formController = new FormController(this);

  /**
   * Type of button
   */
  @property()
  type: 'button' | 'submit' | 'reset';

  /**
   * Style variant
   * - **secondary** renders the button used for secondar actions
   * - **danger** renders the button used for caution actions
   */
  @property()
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'danger' = 'primary';

  /**
   * radiusType
   * Default is default
   * - **default** Radius will be applied to every corner
   * - **left-straight** Radius will not be applied left top and bottom corners.
   * - **right-straight** Radius will not be applied right top and bottom corners.
   */
  @property()
  radiusType?: 'default' | 'left-straight' | 'right-straight' = 'default';

  /**
   * This property is relevant only if `after` slot is set. If set to true show divider before after slot item. Default is false.
   */
  @property({ type: Boolean })
  showDividerBeforeAfterSlot?: boolean = false;

  /**
   * If true, hide left border. Default is false.
   */
  @property({ type: Boolean })
  hideLeftBorder?: Boolean = false;

  /**
   * If true, hide right border. Default is false.
   */
  @property({ type: Boolean })
  hideRightBorder?: Boolean = false;

  /**
   * Show partition or straight border on right. It is used where divider is require to add on button
   */
  @property({ type: Boolean })
  showPartitionOnRight?: Boolean = false;

  /**
   * Target attribute for a link (i.e. set to _blank to open in new tab)
   * - **_blank** yields a link that opens in a new tab
   * - **_self** yields a link that loads the URL into the same browsing context as the current one. This is the default behavior
   * - **_parent** yields a link that loads the URL into the parent browsing context of the current one. If there is no parent, this behaves the same way as _self
   * - **_top** yields a link that loads the URL into the top-level browsing context. If there is no parent, this behaves the same way as _self.
   */
  @property()
  target?: '_blank' | '_self' | '_parent' | '_top';

  /**
   * URL if this is an <a> element - this swaps <button> for <a>
   */
  @property()
  href?: string;

  /**
   * Indicates the name when submitted with form data.
   */
  @property()
  name?: string;

  /**
   * Indicates the aria label to apply to the button.
   */
  @property()
  label?: string;

  /**
   * Indicates the value associated with the name when submitted with form data.
   */
  @property()
  value?: string;

  /**
   * Indicates this button is a toggle button and whether it is pressed or not.
   */
  @property()
  isPressed?: boolean | 'mixed';

  /**
   * Disabled attribute
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Indicates this button is a toggle button and whether it is pressed or not.
   */
  @property({ type: Boolean })
  isExpanded?: boolean;

  /**
   * Visually hide button text (but text is still accessible to assistive technology)
   * 1. Use this for icon-only buttons for accessibility
   */
  @property({ type: Boolean })
  hideText?: boolean;

  /**
   * Full width button
   */
  @property({ type: Boolean })
  fullWidth?: boolean;

  /**
   * aria-controls attribute on the button
   * 1. Used for items like the buttons attached drawers
   */
  @property()
  ariaControls?: string;

  @queryAssignedElements({ slot: 'after' }) afterSlotElements!: HTMLElement[];

  /**
   * Handle click events
   * 1. When we click on button which has type=submit trigger requestSubmit on closest form element in order to invoke submit event on form element
   */
  handleOnClick() {
    /* 1 */
    if (this.type === 'submit' || this.type === 'reset') {
      this.formController.submit(this.type);
    }
  }

  firstUpdated() {
    this.observeSlotChanges();
  }

  observeSlotChanges() {
    // Observe class changes on all elements inside the "after" slot
    this.afterSlotElements.forEach((el) => {
      const observer = new MutationObserver(() => this.updateIconVisibility());
      observer.observe(el, { attributes: true, attributeFilter: ['class'] });
    });
  }

  updateIconVisibility() {
    requestAnimationFrame(() => {
      this.afterSlotElements.forEach((el) => {
        el.style.display = el.classList.contains('d-none') ? 'none' : '';
      });
    });
  }


  render() {
    const componentClassNames = this.componentClassNames('en-c-button', {
      'en-c-button--secondary': this.variant === 'secondary',
      'en-c-button--tertiary': this.variant === 'tertiary',
      'en-c-button--danger': this.variant === 'danger',
      'en-c-button--quaternary': this.variant === 'quaternary',
      'en-c-button--full-width': this.fullWidth === true,
      'en-c-button--icon-only': this.hideText === true,
      'en-is-expanded': this.isExpanded === true,
      'en-c-button__radius--left-straight': this.radiusType === 'left-straight',
      'en-c-button__radius--right-straight': this.radiusType === 'right-straight',
      'en-c-button__left-border--hide': this.hideLeftBorder === true,
      'en-c-button__right-border--hide': this.hideRightBorder === true,
      'en-c-button__right-border-partition': this.showPartitionOnRight === true
    });

    if (this.href) {
      return html`
        <a
          href="${ifDefined(this.href)}"
          role="button"
          class="${componentClassNames}"
          aria-label=${ifDefined(this.label)}
          aria-pressed=${ifDefined(this.isPressed)}
          aria-expanded=${ifDefined(this.isExpanded)}
          aria-controls=${ifDefined(this.ariaControls)}
          target=${ifDefined(this.target)}
        >
          ${this.slotNotEmpty('before') && html`<span class="en-c-button__icon en-slot-before"><slot name="before"></slot></span>`}
          <span class="${this.hideText && 'en-u-is-vishidden'} en-c-button__text">
            <slot></slot>
          </span>
          ${this.slotNotEmpty('after') && this.showDividerBeforeAfterSlot
          ? html`<${this.dividerEl} class="en-c-button__divider" variant="vertical"></${this.dividerEl}>`
          : html``}
          ${this.slotNotEmpty('after') && html`<span class="en-c-button__icon en-slot-after"><slot name="after"></slot></span>`}
        </a>
      ` as TemplateResult<1>;
    } else {
      return html`
        <button
          @click=${this.handleOnClick}
          class="${componentClassNames}"
          type=${ifDefined(this.type)}
          value=${ifDefined(this.value)}
          name=${ifDefined(this.name)}
          aria-label=${ifDefined(this.label)}
          ?disabled=${this.isDisabled}
          aria-pressed=${ifDefined(this.isPressed)}
          aria-expanded=${ifDefined(this.isExpanded)}
          part="button"
        >
          ${this.slotNotEmpty('before') && html`<span class="en-c-button__icon en-slot-before"><slot name="before"></slot></span>`}
          <span class="${this.hideText && 'en-u-is-vishidden'} en-c-button__text">
            <slot></slot>
          </span>
          ${this.slotNotEmpty('after') && this.showDividerBeforeAfterSlot
          ? html`<${this.dividerEl} class="en-c-button__divider" variant="vertical"></${this.dividerEl}>`
          : html``}
          ${this.slotNotEmpty('after') && html`<span class="en-c-button__icon en-slot-after"><slot name="after"></slot></span>`}
        </button>
      ` as TemplateResult<1>;
    }
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENButton.el) === undefined) {
  customElements.define(ENButton.el, ENButton);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-button': ENButton;
  }
}
