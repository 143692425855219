import { PropertyValues, TemplateResult, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENFieldNote } from '../field-note/field-note';
import { ENFloatingTooltip } from '../floating-tooltip/floating-tooltip';
import { ENIconInfo } from '../icon/icons/info';
import { ENTooltip } from '../tooltip/tooltip';
import styles from './checkbox-item.scss';

/**
 * Component: en-checkbox-item
 * - A radio item is a singular checkbox that is meant to be used within the ENCheckbox
 * @slot - The component content that appears next to the checkbox
 * @slot "field-note" - If content is slotted, it will display in place of the fieldNote property
 * @slot "error" - If content is slotted, it will display in place of the errorNote property
 */
export class ENCheckboxItem extends ENElement {
  static el = 'en-checkbox-item';

  private elementMap = register({
    elements: [
      [ENFieldNote.el, ENFieldNote],
      [ENIconInfo.el, ENIconInfo],
      [ENTooltip.el, ENTooltip],
      [ENFloatingTooltip.el, ENFloatingTooltip]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private fieldNoteEl = unsafeStatic(this.elementMap.get(ENFieldNote.el));
  private iconInfoEl = unsafeStatic(this.elementMap.get(ENIconInfo.el));
  private tooltipEl = unsafeStatic(this.elementMap.get(ENTooltip.el));
  private floatingTooltipEl = unsafeStatic(this.elementMap.get(ENFloatingTooltip.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * If true will keep old tooltip, else replace with new tooltip
   * Default is true
   */
  @property({ type: Boolean })
  keepOldTooltip?: boolean = true;

  /**
   * Checked attribute
   * - Changes the component's treatment to represent an checked state
   */
  @property({ type: Boolean })
  isChecked?: boolean;

  /**
   * Indeterminate state
   * - Changes the component's treatment to represent an indeterminate state
   */
  @property({ type: Boolean })
  isIndeterminate?: boolean;

  /**
   * Error state
   * - Changes the component's treatment to represent an error state
   */
  @property({ type: Boolean })
  isError?: boolean;

  /**
   * Disabled attribute
   * - Changes the component's treatment to represent a disabled state
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Required attribute
   * - Sets the checkbox to be required for validation
   */
  @property({ type: Boolean })
  isRequired?: boolean;

  /**
   * Hide label?
   * - If true, hides the label from displaying
   */
  @property({ type: Boolean })
  hideLabel?: boolean;

  /**
   * Hide Checkbox?
   * - If true, hides the checkbox from displaying. Default is false
   */
  @property({ type: Boolean })
  hideCheckbox?: boolean = false;

  /**
   * show highlight on hover?
   * - If true, show highlight on hover. This is relevant when `hideCheckbox` is set.  Default is false.
   */
  @property({ type: Boolean })
  showHighlightOnHover?: boolean = false;

  /**
   * show highlight on selection?
   * - If true, show highlight on selection text. This is relevant when `hideCheckbox` is set.  Default is false.
   */
  @property({ type: Boolean })
  showHighlightOnSelection?: boolean = false;

  /**
   * Name attribute
   */
  @property()
  name?: string;

  /**
   * Value attribute
   */
  @property()
  value?: string;

  /**
   * If search string will be set then dropdown search will also check in this property for searched value
   */
  @property()
  search?: string;

  /**
   *  Error message
   * - An error field note that displays below the checkbox input
   */
  @property()
  errorNote?: string;

  /**
   * If true, then checkbox item will be readonly. Default is false.
   */
  @property({ type: Boolean })
  isReadonly?: boolean = false;

  /**
   * Field note
   * - The helper text that displays below the checkbox input
   */
  @property()
  fieldNote?: string;

  /**
   * Checkbox Help Text
   * - The helper text show directly below checkbox such that both this text and checkbox label are aligned center with respect to checkbox
   */
  @property()
  checkboxLabelSubText?: string;

  /**
   * Id attribute
   * - The ID used for A11y and to associate the label with the input
   */
  @property()
  fieldId?: string;

  /**
   * aria-describedby attribute
   * - Applied to the field note or error note for A11y
   */
  @property()
  ariaDescribedBy?: string;

  /**
   * enableFocusOutline attribute
   * - If true enable on focus outline on checkbox item
   */
  @property()
  enableFocusOutline?: boolean = false;

  /**
   * isActive attribute
   * - If true checkbox item shown highlighted otherwise not
   */
  @property()
  isActive?: boolean = false;

  /**
   * enableLabelFullWidth attribute
   * - If true label will take all the remaining width of the container.
   */
  @property()
  enableLabelFullWidth?: boolean = false;

  /**
   * verticalAlign
   * **center** : Set checkbox in center with respect to label
   * **start** : Set checkbox in start with respect to label
   * **end** : Set checkbox in end with respect to label
   * Default is center
   */
  @property()
  verticalAlign?: 'center' | 'start' | 'end' = 'center';

  /**
   * If true enable full width selection. Default is false.
   */
  @property()
  fullWidthSelection?: boolean = false;

  /**
   * size
   * - Default size is 16px text and 40px checkbox width/height
   * - **sm** renders a smaller size (14px text and 30px checkbox width/height) than default (16px text and 40px checkbox width/height)
   */
  @property({ type: String })
  size?: 'sm' | 'md' = 'md';

  /**
   * hide
   * - Hide List Item. Default is false
   */
  @property({ type: Boolean })
  hide?: boolean = false;

  /**
   * tabableItem attribute
   * - If true checkbox cannot be navigated by tab, instead whole item can be navigated by tab. Default is false.
   */
  @property({ type: Boolean })
  tabableItem?: boolean = false;

  /**
   * itemLabel attribute
   * - If set then aria-label will be set on checkbox item block(not checkbox).
   */
  @property({ type: String })
  ariaItemLabel?: string;

  /**
   * If tooltipInfo will be set then info icon will be shown with label. But if your are providing tooltipInfo then please also provide label.
   */
  @property()
  tooltipInfo?: string;

  /**
   * Icon size
   * - **sm** size is 16px
   * - **md** renders a larger size than sm (20px)
   * - **lg** renders a larger size than the md variant (24px)
   * - **xl** renders a larger size than the lg variant (32px)
   */
  @property()
  infoIconSize?: 'sm' | 'md' | 'lg' | 'xl' = 'md';

  /**
   * Connected callback
   * - Dynamically sets the fieldId and ariaDescribedBy for A11y
   */
  connectedCallback() {
    super.connectedCallback();
    /* 1 */
    this.fieldId = this.fieldId || nanoid();
    if (this.fieldNote) {
      this.ariaDescribedBy = this.ariaDescribedBy || nanoid();
    }
    if (!this.keepOldTooltip) {
      this.tooltipEl = this.floatingTooltipEl;
    }
  }

  protected updated(_changedProperties: PropertyValues): void {
    _changedProperties.forEach((oldValue: any, propName) => {
      if (propName === 'hide' && this.hide !== oldValue) {
        const checkboxItem = this as HTMLElement;
        if (this.hide) {
          checkboxItem.style.display = 'none';
        } else {
          checkboxItem.style.display = 'inherit';
        }
      }
    });
  }

  /**
   * Handle on change events
   * 1. Toggle the checked state
   * 2. If isIndeterminate is true, then on change set it to false
   * 3. Dispatch the custom event
   */
  handleOnChange() {
    if (this.isReadonly) {
      return false;
    }
    /* 1 */
    this.isChecked = !this.isChecked;
    /* 2 */
    if (this.isIndeterminate === true) {
      this.isIndeterminate = false;
    }
    /* 3 */
    this.dispatch({
      eventName: 'change',
      detailObj: {
        checked: this.isChecked,
        indeterminate: this.isIndeterminate,
        value: this.value
      }
    });
  }

  /**
   * Handle on keydown events
   * 1. If the Enter key is pressed, then check the checkbox and dispatch the custom event
   */
  handleOnKeydown(e: KeyboardEvent) {
    if (e.code === 'Enter') {
      this.handleOnChange();
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-checkbox-item', {
      'en-is-indeterminate': this.isIndeterminate === true,
      'en-is-error': this.isError === true,
      'en-is-disabled': this.isDisabled === true,
      'en-has-hidden-label': this.hideLabel,
      'en-enable-focus-outline': this.enableFocusOutline === true,
      'en-is-active': this.isActive === true,
      'en-c-checkbox-item--hide': this.hide === true,
      'en-c-checkbox-item--start': this.verticalAlign === 'start',
      'en-c-checkbox-item--end': this.verticalAlign === 'end'
    });
    return html`
      <div
        class="${componentClassNames}"
        .tabIndex="${this.tabableItem ? '0' : '-1'}"
        role=${ifDefined(this.ariaItemLabel ? 'navigation' : undefined)}
        aria-label=${ifDefined(this.ariaItemLabel)}
        @keydown=${this.hideCheckbox ? this.handleOnKeydown : () => false}
      >
        <div
          class="${classMap({
            'en-c-size--sm': this.size === 'sm',
            'en-c-checkbox-item__container': true,
            'en-show-highlight-hover': this.showHighlightOnHover,
            'en-hide-checkbox': this.hideCheckbox
          })}"
        >
          <div class=${classMap({ 'en-c-checkbox-item__checkbox': true })}>
            <input
              class="en-c-checkbox-item__input"
              type="checkbox"
              id="${this.fieldId}"
              name="${this.name}"
              .value="${this.value}"
              .checked="${ifDefined(this.isChecked ? this.isChecked : undefined)}"
              ?disabled="${this.isDisabled}"
              ?required=${this.isRequired}
              @change=${this.handleOnChange}
              @click=${ifDefined(
                this.isReadonly
                  ? (evt: CustomEvent) => {
                      evt.preventDefault();
                      evt.stopPropagation();
                      return false;
                    }
                  : undefined
              )}
              @keydown=${this.handleOnKeydown}
              aria-describedby="${ifDefined(this.ariaDescribedBy)}"
              .tabIndex="${this.tabableItem ? '-1' : '0'}"
            />
            <span class="en-c-checkbox-item__custom-check"></span>
            <span class="en-c-checkbox-item__ripple"></span>
          </div>
          <div class="${!!this.tooltipInfo ? 'en-label-info' : ''}" style="${this.fullWidthSelection ? 'width:100%' : ''}">
            <label
              class="${classMap({
                'en-c-checkbox-item__label': true,
                'full-width': this.enableLabelFullWidth,
                'en-c-size--sm': this.size === 'sm',
                'en-show-highlight-selection': this.showHighlightOnSelection && this.isChecked
              })}"
              for="${this.fieldId}"
            >
              ${this.slotNotEmpty('prefix-icon') ? html`<slot name="prefix-icon"></slot>` : html``}
              <div class="en-c-checkbox-item__label--content">
                <slot></slot>
                ${!!this.checkboxLabelSubText?.trim() ? html`<div class="en-c-checkbox-item__help-text">${this.checkboxLabelSubText}</div>` : html``}
              </div>
            </label>
            ${!!this.tooltipInfo
              ? html`<${this.tooltipEl}>
          <${this.iconInfoEl} size="${this.infoIconSize}" slot="trigger"></${this.iconInfoEl}>
        ${this.tooltipInfo}
        </${this.tooltipEl}>`
              : html``}
          </div>
        </div>
        ${this.fieldNote || this.slotNotEmpty('field-note')
          ? html`
              <slot name="field-note">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} id=${ifDefined(this.ariaDescribedBy)}> ${this.fieldNote} </${this.fieldNoteEl}>
              </slot>
            `
          : html``}
        ${(this.errorNote || this.slotNotEmpty('error')) && this.isError
          ? html`
              <slot name="error">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${true}> ${this.errorNote} </${this.fieldNoteEl}>
              </slot>
            `
          : html``}
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENCheckboxItem.el) === undefined) {
  customElements.define(ENCheckboxItem.el, ENCheckboxItem);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-checkbox-item': ENCheckboxItem;
  }
}
