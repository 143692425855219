import {html} from 'lit';

export default html`
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="36" height="36" rx="2" fill="#6930DF"/>
<path d="M27.3333 9C27.3333 8.73478 27.4387 8.48043 27.6262 8.29289C27.8138 8.10536 28.0681 8 28.3333 8C28.5986 8 28.8529 8.10536 29.0404 8.29289C29.228 8.48043 29.3333 8.73478 29.3333 9C29.3333 9.26522 29.228 9.51957 29.0404 9.70711C28.8529 9.89464 28.5986 10 28.3333 10C28.0681 10 27.8138 9.89464 27.6262 9.70711C27.4387 9.51957 27.3333 9.26522 27.3333 9ZM29.2433 9C29.2456 8.87972 29.2236 8.76022 29.1787 8.64861C29.1338 8.53701 29.0669 8.43557 28.982 8.35035C28.8971 8.26513 28.7959 8.19787 28.6845 8.15257C28.573 8.10727 28.4536 8.08486 28.3333 8.08667C28.2134 8.08622 28.0946 8.10957 27.9838 8.15535C27.873 8.20113 27.7723 8.26845 27.6877 8.35339C27.6031 8.43833 27.5361 8.53922 27.4907 8.65021C27.4454 8.7612 27.4225 8.88009 27.4233 9C27.4211 9.12028 27.4431 9.23978 27.488 9.35139C27.5328 9.46299 27.5997 9.56443 27.6846 9.64965C27.7696 9.73487 27.8707 9.80213 27.9822 9.84743C28.0936 9.89273 28.2131 9.91514 28.3333 9.91333C28.4532 9.91378 28.572 9.89043 28.6829 9.84465C28.7937 9.79887 28.8943 9.73155 28.979 9.64661C29.0636 9.56167 29.1306 9.46078 29.1759 9.34979C29.2213 9.2388 29.2442 9.11991 29.2433 9ZM27.94 8.46H28.4C28.5112 8.45428 28.6202 8.4926 28.7033 8.56667C28.7303 8.59244 28.7516 8.62352 28.7659 8.65794C28.7803 8.69236 28.7873 8.72938 28.7867 8.76667C28.7865 8.84095 28.7594 8.91264 28.7103 8.96843C28.6613 9.02422 28.5936 9.06031 28.52 9.07L28.8533 9.48H28.6667L28.3733 9.09333H28.11V9.48H27.9567L27.94 8.46ZM28.3867 8.96C28.53 8.96 28.63 8.89667 28.63 8.78C28.63 8.67333 28.54 8.60333 28.3933 8.60333H28.0933V8.96667L28.3867 8.96Z" fill="white"/>
<path d="M26.6667 8.33333V11.6667C26.6667 11.7551 26.6315 11.8399 26.569 11.9024C26.5065 11.9649 26.4217 12 26.3333 12H12.3333C12.2449 12 12.1601 12.0351 12.0976 12.0976C12.0351 12.1601 12 12.2449 12 12.3333V19.17C12.0002 19.4331 11.9485 19.6937 11.8478 19.9368C11.7472 20.1799 11.5995 20.4007 11.4133 20.5867L8 24V8.33333C8 8.24493 8.03512 8.16014 8.09763 8.09763C8.16014 8.03512 8.24493 8 8.33333 8H26.3333C26.4217 8 26.5065 8.03512 26.569 8.09763C26.6315 8.16014 26.6667 8.24493 26.6667 8.33333ZM16.3333 20H23.7167C23.8051 20 23.8899 19.9649 23.9524 19.9024C24.0149 19.8399 24.05 19.7551 24.05 19.6667V16.3333C24.05 16.2449 24.0149 16.1601 23.9524 16.0976C23.8899 16.0351 23.8051 16 23.7167 16H16.3333C16.2449 16 16.1601 16.0351 16.0976 16.0976C16.0351 16.1601 16 16.2449 16 16.3333V19.6667C16 19.7551 16.0351 19.8399 16.0976 19.9024C16.1601 19.9649 16.2449 20 16.3333 20ZM8 24L11.4133 27.4133C11.5993 27.5995 11.8201 27.7472 12.0632 27.8478C12.3063 27.9485 12.5669 28.0002 12.83 28H26.3333C26.4217 28 26.5065 27.9649 26.569 27.9024C26.6315 27.8399 26.6667 27.7551 26.6667 27.6667V24.3333C26.6667 24.2449 26.6315 24.1601 26.569 24.0976C26.5065 24.0351 26.4217 24 26.3333 24H8Z" fill="white"/>
</svg>

`;
